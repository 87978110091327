<template>
<div>
  <popUp v-if="toggle" v-on:doClose="close" :size="'small'">
    <div class="pop-title" slot="title">
      <h2>區間報表</h2>
    </div>
    <div slot="info" class="info-main" >
      {{ $t('Personal.Record.Date_settings') }}:
      <div class="d-flex justify-content-between">
        <date-picker valueType="format" v-model="range" :format="'YYYY-MM-DD HH:mm'" :showTimeHeader="true" type="datetime" :showSecond="false" range placeholder="請選擇日期區間"></date-picker>
        <button type="button" v-if="$route.params.type !== 'summary'" @click="postIntervalReports" class="btn btn-danger">匯出報表</button>
      </div>
    </div>
  </popUp>
  <div class="row">
    <div class="col-12">
      <div>
        <div class="d-flex title-box">
          <div class="title d-sm-flex pointer" :class="{'active' : $route.params.type === 'summary'}">
            <div class="my-auto" @click="goPage('summary')">
              目標總覽
            </div>
          </div>
          <div class="title  d-sm-flex pointer" :class="{'active' : $route.params.type === 'list'}">
            <div class="my-auto" @click="goPage('list')">
              目標完成報表
            </div>
          </div>
          <div class="title d-sm-flex pointer" :class="{'active' : $route.params.type === 'placement-exam'}">
            <div class="my-auto" @click="goPage('placement-exam')">
              {{ $t('Overview.classify') }}
            </div>
          </div>
          <div class="title d-sm-flex pointer" :class="{'active' : $route.params.type === 'monthly-exam'}">
            <div class="my-auto" @click="goPage('monthly-exam')">
              月考
            </div>
          </div>
        </div>
        <div class="download-btn">
          <button type="button" v-if="$route.params.type === 'list'" @click="openPopUp" class="btn btn-danger mx-2">區間報表</button>
          <button type="button" v-if="$route.params.type !== 'summary'" @click="postReports" class="btn btn-danger">匯出報表</button>
        </div>
      </div>
    </div>
    <div class="col-12 row" v-if="$route.params.type=== 'summary'">
      <div class="col-12">
        <h6 v-if="standard" style="text-align:left;">
          <span style="font-weight:bold;">企業標準說明：</span>
          <br>
          <span v-html="standard.replace(/(\r\n)|(\n)/g,'<br>')"></span>
        </h6>
      </div>
      <div class="col-md-3" v-for="(item, key,index) in overview" :key="index">
        <div class="target-box my-3" v-if="item.rule !== 0 && item.rule !== '00:00:00'">
          <div class="target-title">
            {{$t( 'Overview.'+ key )}}
          </div>
          <div class="target-content">
            <p v-if="key !== 'classify'">
              目標：{{item.rule}} {{unit(key)}}
            </p>
            <p v-else>
              目標：完成學前測驗
            </p>
            <p>
              完成人數：{{item.pass_count}} 人
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(item.pass_count/item.total_count)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(item.pass_count/item.total_count)}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="target-box my-3 disabled" v-else>
          <div class="target-title">
            {{$t( 'Overview.'+ key )}}
          </div>
          <div class="target-content disabled">
            <p>
              目標：未設定
            </p>
            <p>
              完成人數：未設定
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(0)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(0)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="$route.params.type=== 'list'">
      <table class="rwd-table my-3">
        <tbody>
          <tr>
            <th>{{ $t('Overview.classify') }}</th>
            <th>{{ $t('Overview.time') }}</th>
            <th>{{ $t('Overview.course') }}</th>
            <th>{{ $t('Overview.exam') }}</th>
            <th>{{ $t('Overview.exam_score') }}</th>
            <th>{{ $t('Overview.exam_advance') }}</th>
            <th>{{ $t('Overview.exam_advance_with_percent') }}</th>
            <th>完成課後挑戰</th>
          </tr>
          <tr>
            <td class="rwd-td" style="display:none" data-th="總表">
              <!-- {{overview}} -->
            </td>
            <td data-th="學前測驗">
              {{overview.classify.pass_count}}人
            </td>
            <td data-th="學習總時間">
              {{overview.time.pass_count}}人
            </td>
            <td data-th="通過單元測驗課數">
              {{overview.course.pass_count}}人
            </td>
            <td data-th="完成月考數">
              {{overview.exam.pass_count}}人
            </td>
            <td data-th="月考成績">
              {{overview.exam_score.pass_count}}人
            </td>
            <td data-th="任一月考>學前測驗">
              {{overview.exam_advance.pass_count}}人
            </td>
            <td data-th="月考進步百分比">
              {{overview.exam_advance_with_percent.pass_count}}人
            </td>
            <td data-th="完成課後挑戰">
              {{overview.practice.pass_count}}人
            </td>
          </tr>
        </tbody>
      </table>
      <table class="rwd-table my-3" v-for="(item, index) in list" :key="index">
        <tbody>
          <tr>
            <th style="text-align:center;width:150px" rowspan="2">{{item.name}}</th>
            <th>{{ $t('Overview.classify') }}</th>
            <th v-if="item.progress.time.rule !== '00:00:00'">{{ $t('Overview.time') }}</th>
            <th v-if="item.progress.course.rule>0">{{ $t('Overview.course') }}</th>
            <th v-if="item.progress.exam.rule>0">{{ $t('Overview.exam') }}</th>
            <th v-if="item.progress.exam_score.rule>0">{{ $t('Overview.exam_score') }}</th>
            <th v-if="item.progress.exam_advance.rule>0">{{ $t('Overview.exam_advance') }}</th>
            <th v-if="item.progress.exam_advance_with_percent.rule>0">月考進步百分比</th>
            <th v-if="item.progress.practice.rule>0">完成課後挑戰</th>
          </tr>
          <tr>
            <td class="rwd-td" style="display:none" :data-th="$t('Personal.Updated_password.Name')">
              {{item.name}}
            </td>
            <td data-th="學前測驗">
              <font-awesome-icon size="lg" style="color:green"  v-if="item.progress.classify.user>0" :icon="['fa', 'check-circle']" />
              <font-awesome-icon size="lg" style="color:red" v-else :icon="['fa', 'times-circle']" />
            </td>
            <td data-th="學習總時間" v-if="item.progress.time.rule !== '00:00:00'">
              <font-awesome-icon size="lg" style="color:green"  v-if="item.progress.time.is_pass" :icon="['fa', 'check-circle']" />
              <font-awesome-icon size="lg" style="color:red" v-else :icon="['fa', 'times-circle']" />
            </td>
            <td data-th="通過單元測驗課數" v-if="item.progress.course.rule>0">
              <font-awesome-icon size="lg" style="color:green" v-if="item.progress.course.is_pass" :icon="['fa', 'check-circle']" />
              <font-awesome-icon size="lg" style="color:red" v-else :icon="['fa', 'times-circle']" />
            </td>
            <td data-th="完成月考數" v-if="item.progress.exam.rule>0">
              <font-awesome-icon size="lg" style="color:green" v-if="item.progress.exam.is_pass" :icon="['fa', 'check-circle']" />
              <font-awesome-icon size="lg" style="color:red" v-else :icon="['fa', 'times-circle']" />
            </td>
            <td data-th="月考成績" v-if="item.progress.exam_score.rule>0">
              <font-awesome-icon size="lg" style="color:green" v-if="item.progress.exam_score.is_pass" :icon="['fa', 'check-circle']" />
              <font-awesome-icon size="lg" style="color:red" v-else :icon="['fa', 'times-circle']" />
            </td>
            <td data-th="任一月考>學前測驗" v-if="item.progress.exam_advance.rule>0">
              <font-awesome-icon size="lg" style="color:green" v-if="item.progress.exam_advance.is_pass" :icon="['fa', 'check-circle']" />
              <font-awesome-icon size="lg" style="color:red" v-else :icon="['fa', 'times-circle']" />
            </td>
            <td data-th="月考進步百分比" v-if="item.progress.exam_advance_with_percent.rule>0">
              <font-awesome-icon size="lg" style="color:green" v-if="item.progress.exam_advance_with_percent.is_pass" :icon="['fa', 'check-circle']" />
              <font-awesome-icon size="lg" style="color:red" v-else :icon="['fa', 'times-circle']" />
            </td>
            <td data-th="完成課後挑戰" v-if="item.progress.practice.rule>0">
              <font-awesome-icon size="lg" style="color:green" v-if="item.progress.practice.is_pass" :icon="['fa', 'check-circle']" />
              <font-awesome-icon size="lg" style="color:red" v-else :icon="['fa', 'times-circle']" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12" v-if="$route.params.type=== 'placement-exam'">
      <table class="rwd-table my-3" v-for="(item, index) in placementExams" :key="index">
        <tbody>
          <tr>
            <th style="text-align:center;width:150px">{{$t('Personal.Updated_password.Name')}}</th>
            <th>{{ $t('Personal.Exam.Result') }}</th>
            <th>測驗時間</th>
            <th>聽力使用時間</th>
            <th>{{ $t('Personal.Exam.Listening_score') }}</th>
            <th>閱讀使用時間</th>
            <th>{{ $t('Personal.Exam.Reading_score') }}</th>
            <th>作答使用時間</th>
            <th>測驗成績</th>
          </tr>
          <tr v-if="item.exams !== null">
            <td class="rwd-td" :data-th="$t('Personal.Updated_password.Name')">
              {{item.name}}
            </td>
            <td :data-th="$t('Personal.Exam.Result')">
              {{item.exams.cefr}}
            </td>
            <td data-th="測驗時間">
              {{item.exams.time}}
            </td>
            <td data-th="聽力使用時間">
              {{item.exams.listening_time}}
            </td>
            <td data-th="聽力成績">
              {{item.exams.listening_score}}
            </td>
            <td data-th="閱讀使用時間">
              {{item.exams.reading_time}}
            </td>
            <td data-th="閱讀成績">
              {{item.exams.reading_score}}
            </td>
            <td data-th="作答使用時間">
              {{item.exams.time}}
            </td>
            <td data-th="測驗成績">
              {{item.exams.score}}
            </td>
          </tr>
          <tr v-else>
            <td class="rwd-td"  :data-th="$t('Personal.Updated_password.Name')">
              {{item.name}}
            </td>
            <td :data-th="$t('Personal.Exam.Result')">
              未測驗
            </td>
            <td data-th="測驗時間">
              未測驗
            </td>
            <td data-th="聽力使用時間">
              未測驗
            </td>
            <td data-th="聽力成績">
              未測驗
            </td>
            <td data-th="閱讀使用時間">
              未測驗
            </td>
            <td data-th="閱讀成績">
              未測驗
            </td>
            <td data-th="作答使用時間">
              未測驗
            </td>
            <td data-th="測驗成績">
              未測驗
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12" v-if="$route.params.type=== 'monthly-exam'">
      <div style="float:left">
        <span>近期月份: </span>
        <select class="mr-3" v-model="recentMonth">
          <option selected value="3">3</option>
          <option value="6">6</option>
          <option value="12">12</option>
        </select>
      </div>
      <div v-for="(item, index) in monthlyExams" :key="index">
        <table class="rwd-table my-3">
          <tbody >
            <tr>
              <th>{{$t('Personal.Updated_password.Name')}}</th>
              <th v-for="(el, idx) in item.exams" :key="idx">{{el.date}}</th>
            </tr>
            <tr>
              <td class="rwd-td" :data-th="$t('Personal.Updated_password.Name')" style="width:250px">
                {{item.name}}
              </td>
              <td v-for="(el, idx) in item.exams" :key="idx" :data-th="el.date">
                {{el.score}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
      <pagination v-if="pageCount>1 && $route.params.type !== 'summary'" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
  </div>
</div>
</template>
<script>
// @ is an alias to /src
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import PopUp from '@/components/PopUp.vue'
import Pagination from '../../components/Pagination.vue'
import api from '../../store/api'
export default {
  name: 'Overview',
  data () {
    return {
      overview: {},
      list: [],
      placementExams: [],
      monthlyExams: [],
      standard: '',
      toggle: false,
      recentMonth: 3,
      range: [ moment(new Date(new Date().getTime()- 6 * 24 * 3600 * 1000)).format('YYYY-MM-DD hh:mm:ss'), moment(new Date()).format('YYYY-MM-DD hh:mm:ss')],
      pageIndex: 1,
      pageCount: 0
    }
  },
  computed: {
    groupId () {
      return this.$route.params.groupId
    }
  },
  mounted() {
    this.scrollTop()
    this.getOverview(this.groupId)
    // this. getOverviewList(this.groupId)
    // this.getPlacementExams(this.groupId)
    // this.getMonthlyExams(this.groupId)
    this.getStandard()
  },
  watch: {
    groupId(val) {
      switch (val) {
        case 'list':
          this.getOverviewList(val)
          break
        case 'placement-exam':
          this.getPlacementExams(val)
          break
        case 'monthly-exam':
          this.getMonthlyExams(val)
          break
      }
    },
    '$route.params.type': {
      handler: function(val) {
        switch (val) {
          case 'list':
            this.getOverviewList(this.groupId)
            break
          case 'placement-exam':
            this.getPlacementExams(this.groupId)
            break
          case 'monthly-exam':
            this.getMonthlyExams(this.groupId)
            break
        }
        this.pageIndex = 1
        this.pageCount = 0
      },
      deep: true,
      immediate: true
    },
    recentMonth (val) {
      this.getMonthlyExams(this.groupId)
    }
  },
  methods: {
    goPage (el) {
      this.$router.push({ name: 'Overview',  params: { type: el, groupId: this.groupId }})
    },
    async getStandard () {
      const { data } = await api.get(`/me`)
      this.standard = data.enterprise_standard
    },
    async getOverview (id) {
      const { data } = await api.get(`/records/groups/${id}/learning_progress`)
      this.overview = data.data
    },
    async getOverviewList (id) {
      const params = {
        page: this.pageIndex,
        pageSize: 10
      }
      const { data } = await api.get(`/records/groups/${id}/learning_progress/list`, { params } )
      this.list = data.data
      this.pageIndex = data.pageIndex
      this.pageCount = data.pageCount
    },
    async getPlacementExams (id) {
      const params = {
        page: this.pageIndex,
        pageSize: 10
      }
      const { data } = await api.get(`/records/groups/${id}/placement_exams`, { params })
      this.placementExams = data.data
      this.pageIndex = data.pageIndex
      this.pageCount = data.pageCount
    },
    async getMonthlyExams (id) {
      const params = {
        recent: this.recentMonth,
        page: this.pageIndex,
        pageSize: 10
      }
      const { data } = await api.get(`/records/groups/${id}/monthly_exams`, { params })
      this.monthlyExams = data.data
      this.pageIndex = data.pageIndex
      this.pageCount = data.pageCount
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getOverviewList(this.groupId)
      this.getPlacementExams(this.groupId)
      this.getMonthlyExams(this.groupId)
    },
    toPercent (num) {
      if ( Number(num*100).toFixed(0) >= 100) {
        return '100%'
      } else {
        return Number(num*100).toFixed(0) + '%'
      }
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    async postReports () {
      let type = ''
      switch (this.$route.params.type) {
        case 'list':
          type = 'progress'
          break
        case 'placement-exam':
          type = 'placementExams'
          break
        case 'monthly-exam':
          type = 'monthlyExams'
          break
      }
      try {
        await api.get(`/reports/groups/${this.groupId}/${type}`)
        this.$swal({
          title: '報表計算完成後，將自動發送至您的信箱',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '确定'
        })
      } catch (error) {
        if (error) {
          this.$swal({
            title: '目前報表功能異常，請聯絡管理者',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '确定'
          })
        }
      }
    },
    async postIntervalReports () {
      const params = {
        beginAt: moment(this.range[0]).format('YYYY-MM-DD hh:mm'),
        endAt: moment(this.range[1]).format('YYYY-MM-DD hh:mm')
      }
      try {
        await api.get(`/reports/groups/${this.groupId}/period`, {params})
        this.$swal({
          title: '報表計算完成後，將自動發送至您的信箱',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '确定'
        })
      } catch (error) {
        if (error) {
          this.$swal({
            title: '目前報表功能異常，請聯絡管理者',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '确定'
          })
        }
      }
      this.toggle = false
    },
    openPopUp () {
      this.toggle = true
    },
    close (close) {
      this.toggle = close
    },
    unit (key) {
      switch (key) {
        case 'time':
          break
        case 'exam_advance_with_percent':
          return '%'
        case 'exam':
          return '次'
        case 'exam_score':
          return '分'
        case 'exam_advance':
          return '分'
        case 'practice':
          return '次'
        case 'course':
          return '課'
      }
    }
  },
  components: {
    Pagination,
    PopUp,
    DatePicker
  }
}
</script>
<style lang="scss" scoped>
  .menu {
    line-height: 2px;
    margin-bottom: 20px;
    border-bottom: solid 1px #ddd;
    border-top: solid 1px #ddd;
    .title {
      margin: 20px;
      font-weight: bolder;
    }
  }
  .target-box {
    height: 180px;
    overflow: hidden;
    background-color: #ededed;
    border: .5px solid rgb(202, 202, 202);
    border-radius: 5px;
    .target-title {
      padding: 8px;
      background-color: #0041b9;
      color: #fff;
    }
    .target-content {
      text-align: left;
      padding: 20px;
      height: 80%;
    }
    .progress {
      margin: 5px;
      height: 10px;
      background-color: #fff;
      .progress-bar {
        background-color: red;
      }
    }
  }
  .disabled {
    filter: brightness(.8);
  }
  .title-box {
    margin-bottom: 20px;
    border-top: #aaa solid 1px;
    border-bottom: #aaa solid 1px;
    padding: 10px 0;
    .title {
      color: #aaa;
      margin: 0 50px 0 0;
      cursor: pointer;
    }
    .active {
      color: black;
    }
  }
  .rwd-table {
  min-width: 100%;
  max-width: 100% !important;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
  border: solid 1px #fff;
}

.rwd-table td {
  display: block;
  border: solid 1px #fff;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) "： ";
  font-weight: bold;
  display: inline-block;
  color: #000;
}
.download-btn {
  text-align: right;
}
.rwd-table th,
.rwd-table td {
  white-space: nowrap;
  text-align: left;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-td {
    width: 100% !important;
    display: block !important;
    background-color: #04a2d6;
  }
  button  {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}
  @media(max-width: 1080px){
    .banner-box {
      display: none;
    }
    .personal_info {
      img {
        display: none;
      }
    }
  }
</style>
