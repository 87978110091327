<template>
  <div class="container-fluid my-3" style="min-height:50vh">
    <div class="row">
      <div class="col-12">
        <div class="d-flex title-box">
          <!-- <div class="title" :class="{'active': currentPage==='classify'}"  @click="doSubTitle('classify')">{{$t('Personal.Exam.Placement_test')}}</div>
          <div class="title" v-if="examRole" :class="{'active': currentPage==='regular'}" @click="doSubTitle('regular')">{{$t('Personal.Exam.Monthly_mock_test')}}</div> -->
          <div class="title" v-if="examRole" :class="{'active' : currentPage==='simulation'}"  @click="doSubTitle('simulation')">{{$t('Personal.Exam.More_practice')}}</div>
        </div>
      </div>
    </div>
    <div class="alert alert-warning" role="alert">
      為提供最佳應考體驗，請以電腦完成本區測驗
    </div>
    <!-- <div v-if="currentPage === 'classify'">
      <div style="text-align:left">
        <h6>
          在導入課程前，請先進行學前測驗，以了解目前TOEIC與對應CEFR英語程度，以下說明：
        </h6>
        <ul class="pl-3">
          <li>每位學員限定只能測驗一次，建議以電腦考試。</li>
          <li>測驗題型：模擬最新多益題型，考完立即呈現成績結果。</li>
          <li>測驗題數：包含聽力50題與閱讀50題，滿分990分。</li>
          <li>測驗時間：約 60 分鐘，畫面右上角有倒數計時，提醒作答剩餘時間。</li>
        </ul>
      </div>
      <table class="rwd-table">
        <tr>
          <th>{{$t('Personal.Exam.Result')}}</th>
          <th>{{$t('Personal.Exam.Exam_date')}}</th>
          <th>{{$t('Personal.Exam.Test_time')}}</th>
          <th>{{$t('Personal.Exam.Listening_score')}}</th>
          <th>{{$t('Personal.Exam.Reading_score')}}</th>
          <th>{{$t('Personal.Exam.Total_score')}}</th>
          <th class="rwd-hide">{{$t('Personal.Exam.State')}}</th>
        </tr>
        <tr v-for="(item, index) in exams" :key="index">
          <td class="rwd-td" :data-th="$t('Personal.Exam.Result')"> {{item.cefr}}</td>
          <td :data-th="$t('Personal.Exam.Exam_date')"> {{item.finished_at?item.finished_at:'N/A'}}</td>
          <td :data-th="$t('Personal.Exam.Test_time')"> {{item.total_time}}</td>
          <td :data-th="$t('Personal.Exam.Listening_score')"> {{item.listening_score?item.listening_score:'-'}}</td>
          <td :data-th="$t('Personal.Exam.Reading_score')"> {{item.listening_score?item.reading_score:'-'}}</td>
          <td :data-th="$t('Personal.Exam.Total_score')"> {{item.listening_score?item.score:'-'}}</td>
          <td class="rwd-hide" :data-th="$t('Personal.Exam.Start')">
            <button v-if="!item.finished_at" type="button" @click="goExam(item)" class="btn btn-danger">Start</button>
            <button v-else :disabled="item.finished_at" type="button" class="btn btn-danger">Finished</button>
          </td>
        </tr>
      </table>
      <div class="explanation" v-for="(item, index) in chats" :key="index" style="text-align:left">
        <div v-if="item.level=== cefr">
          <h4>您的學前測驗分級結果是 <span class="red-text"> {{cefr}}</span>，以下是您的英語能力與學習建議說明:</h4>
          <div class="mt-3">
            <h5 class="red-text">英語能力</h5>
            <ul>
              <li v-for="(txt, idx) in item.text" :key="idx">{{txt}}</li>
            </ul>
          </div>
          <div class="mt-3">
            <h5 class="red-text">學習建議</h5>
            <p>{{item.advice}}​</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentPage === 'regular'">
      <div style="text-align:left">
        <h6>
          為挑戰自我實力，每月提供新多益模擬測驗，以提升多益應考實力，以下說明：
        </h6>
        <ul class="pl-3">
          <li>每位學員限定每月只能測驗一次，建議以電腦考試。</li>
          <li>測驗題型：模擬最新多益題型，考完立即呈現成績結果。</li>
          <li>測驗題數：包含聽力50題與閱讀50題，滿分990分。</li>
          <li>測驗時間：約 60 分鐘，畫面右上角有倒數計時，提醒作答剩餘時間。</li>
          <li>月考期間：每月最後10天自動開放。</li>
        </ul>
        <h6>最近開放時間:   {{moment(monthlyExam.begin_at).format('YYYY/MM/DD') }} - {{moment(monthlyExam.end_at).format('YYYY/MM/DD') }}</h6>
      </div>
      <div class="alert alert-dark" role="alert">
        當月測驗
      </div>
      <div  class="d-flex">
        <table>
          <tr>
            <th>{{$t('Personal.Exam.Month')}}</th>
            <th>{{$t('Personal.Exam.Exam_date')}}</th>
            <th>{{$t('Personal.Exam.Test_time')}}</th>
            <th>{{$t('Personal.Exam.Listening_score')}}</th>
            <th>{{$t('Personal.Exam.Reading_score')}}</th>
            <th>{{$t('Personal.Exam.Total_score')}}</th>
          </tr>
          <tr>
            <td :data-th="$t('Personal.Exam.Month')">{{monthlyExam.month}}</td>
            <td :data-th="$t('Personal.Exam.Exam_date')" style="width:200px">{{monthlyExam.finished_at?monthlyExam.finished_at:'N/A'}}</td>
            <td :data-th="$t('Personal.Exam.Test_time')">{{monthlyExam.total_time}}</td>
            <td :data-th="$t('Personal.Exam.Listening_score')">{{monthlyExam.listening_score?monthlyExam.listening_score:'-'}}</td>
            <td :data-th="$t('Personal.Exam.Reading_score')">{{monthlyExam.reading_score?monthlyExam.reading_score:'-'}}</td>
            <td :data-th="$t('Personal.Exam.Total_score')">{{monthlyExam.score?monthlyExam.score:'-'}}</td>
          </tr>
        </table>
        <button style="width:250px" v-if="allowExam" type="button" class="btn btn-outline-danger" @click="goExam(monthlyExam)">Start</button>
        <button style="width:250px" v-if="!allowExam"  type="button" class="btn btn-outline-danger" disabled>{{ timeStamp(time)}}後開始測驗</button>
        <button style="width:250px" v-if="monthlyExam.finished_at ==='null' && allowExam"  type="button" class="btn btn-outline-danger" disabled>完成考試</button>
      </div>
      <div class="record" v-if="exams">
        <div class="alert alert-dark mt-4" role="alert" v-if="exams">
          測驗紀錄
        </div>
        <table v-for="(item, index) in exams" :key="index">
          <tr>
            <th>{{$t('Personal.Exam.Month')}}</th>
            <th>{{$t('Personal.Exam.Exam_date')}}</th>
            <th>{{$t('Personal.Exam.Test_time')}}</th>
            <th>{{$t('Personal.Exam.Listening_score')}}</th>
            <th>{{$t('Personal.Exam.Reading_score')}}</th>
            <th>{{$t('Personal.Exam.Total_score')}}</th>
          </tr>
          <tr>
            <td :data-th="$t('Personal.Exam.Month')">{{item.month}}</td>
            <td :data-th="$t('Personal.Exam.Exam_date')" style="width:200px">{{item.finished_at?item.finished_at:'未測驗'}}</td>
            <td :data-th="$t('Personal.Exam.Test_time')">{{item.total_time}}</td>
            <td :data-th="$t('Personal.Exam.Listening_score')">{{item.listening_score?item.listening_score:'-'}}</td>
            <td :data-th="$t('Personal.Exam.Reading_score')">{{item.reading_score?item.reading_score:'-'}}</td>
            <td :data-th="$t('Personal.Exam.Total_score')">{{item.score?item.score:'-'}}</td>
          </tr>
        </table>
      </div>
    </div> -->
    <div v-if="currentPage === 'simulation'">
      <div v-for="(item, index) in exams" :key="index">
        <div class="d-flex">
          <table class="rwd-table">
            <tbody>
              <tr>
                <th style="text-align:center;" rowspan="2"> {{`第 ${index+1} 回`}}</th>
                <th style="width:200px">{{$t('Personal.Exam.Exam_date')}}</th>
                <th>{{$t('Personal.Exam.Test_time')}}</th>
                <th>{{$t('Personal.Exam.Listening_score')}}</th>
                <th>{{$t('Personal.Exam.Reading_score')}}</th>
                <th>{{$t('Personal.Exam.Total_score')}}</th>
                <th>{{$t('Personal.Exam.Number_of_exercises')}}</th>
              </tr>
              <tr>
                <td class="rwd-td" style="display:none" :data-th="item.name">
                </td>
                <td :data-th="$t('Personal.Exam.Exam_date')">
                  {{item.finished_at?item.finished_at:'N/A'}}
                </td>
                <td :data-th="$t('Personal.Exam.Test_time')">
                  {{item.total_time}}
                </td>
                <td :data-th="$t('Personal.Exam.Listening_score')">
                  {{item.listening_score?item.listening_score:'-'}}
                </td>
                <td :data-th="$t('Personal.Exam.Reading_score')">
                  {{item.reading_score?item.reading_score:'-'}}
                </td>
                <td :data-th="$t('Personal.Exam.Total_score')">
                  {{item.score?item.score:'-'}}
                </td>
                <td :data-th="$t('Personal.Exam.Number_of_exercises')">
                  {{item.count}}
                </td>
              </tr>
            </tbody>
          </table>
          <button :disabled="item.count>=1" type="button" style="width:80px" class="btn btn-danger m-1" @click="goExam(item)">{{$t('Personal.Exam.Start')}}</button>
          <button :disabled="item.count<1 || permissions.toUpperCase() !=='Y'" type="button" style="width:80px" class="btn btn-danger m-1" @click="goDetailAnswer(item)">{{$t('Personal.Exam.Details')}}</button>
          <button :disabled="item.count<1" type="button" style="width:80px" class="btn btn-danger m-1" @click="downloadCertificate(item)">{{$t('Personal.Exam.Certificate')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/api'
export default {
  name: 'ExamArea',
  data () {
    return {
      exams: [],
      currentPage: 'simulation',
      monthlyExam: {},
      allowExam: false,
      certificate: {},
      time: 0,
      cefr: 'A2',
      chats: [
        {
          level: 'A1',
          text:[
            '運用所學的字母拼讀規則拼寫、讀出英文字詞與簡單語句。​',
            '以正確的發音及適切語速朗讀簡易對話、歌謠韻文、故事短劇。​',
            '聽懂日常生活應對中的常用語句，如:自我介紹、居住地、人際關係，​ 並能作簡短的回應。'
          ],
          advice: '此階段學員建議從KK音標、發音規則開始學起，熟悉英文的音節、重音及語調，跟著教材開口說英文。接著背誦基礎英語1200字，累積生活英語的字彙量。有了一定的字彙量後，即可學習簡易的句子，例如「學習資源」中的「社交必備句」或「生活對話好用句」。藉由使用「句子跟讀」功能，複誦、並記下教材中的句子，累積學員基礎對話實力。'
        },
        {
          level: 'A2',
          text:[
            '聽懂日常生活對話、簡易故事、簡易廣播，並能以簡單的字詞、句子記下要點。​​',
            '看懂日常溝通中簡易的書信、 簡訊、 留言、賀卡、邀請卡等，並能以口語或書面作簡短的回應。​​',
            '掌握重要的閱讀技巧如擷取大意、猜測字義、推敲文意、預測後續文意及情節發展……等。​'
          ],
          advice: '此階段學員已具備基本的字彙量，建議藉由互動教材中「單字」、「字典」查找的功能，更多學習生字、擴增字彙量；並使用「收藏」功能，建立專屬自己的單字本。針對想增進閱讀能力的學員，建議從「生活英語」中的「健康好食尚」「經典文學館」「名人超新星」三門課學起。聽力與口說的部份則可瀏覽「節慶文化」、「週末放輕鬆」、「生活實用情境」三門課，跟著課程影片聆聽與跟讀，培養實用語境下的各種英語表達能力。'
        },
        {
          level: 'B1',
          text:[
            '以正確的發音及適切的斷句、節奏、語調、語氣及速度，流暢地朗讀短文、短劇及故事，並能以自己的話轉述談話或故事。​',
            '在工作時，能閱讀工作須知、公告、操作手冊、例行的文件、傳真、電報等。​​',
            '能了解及閱讀比較不同體裁、不同主題之文章，並據以發表心得、進行評析。​'
          ],
          advice: '此階段學員已具備一定的閱讀能力與字彙量。也能在工作時，閱讀例行的英文文件。建議從「生活英語」中的「健康好食尚」「名人超新星」、「節慶文化」、「週末放輕鬆」、「生活實用情境」幾門課的B1進階課程，大聲朗讀課文、跟讀對話，增進英語的語感與中級表達能力。商業英語部份，可從「學習資源」中的「商英必備句」累積職場必備語彙庫，並從「商業英語」中的「商業接待」、「電話客服」課程，學習如何用英語接待客戶、與客戶電話溝通。寫的部份，可使用「寫作教室」中的基礎商業寫作課程，進行文章寫作練習。'
        },
        {
          level: 'B2',
          text:[
            '能夠理解具體或抽象的話題，包括與自身專業領域相關的技術性研討。​',
            '日常生活中聽得懂演講、報導與節目；工作時，能聽懂簡報、討論、產品介紹及操作說明。 ',
            '能夠接待外籍人士、介紹工作內容、洽談業務、在會議中發言，以及撰寫工作報告和書信。​'
          ],
          advice: '此階段學員已能理解不同主題、具體或抽象的話題，也對可用英語進行簡報、產品介紹與自身專業領域相關用字。建議學員從「商業英語」中的「商務會議」開始，掌握會前籌備、主持會議英語、視訊會議必備英語語彙。後續可持續以「經營管理」、「業務行銷」、「商展旅行」等課程，增進商業英語溝通能力。新聞英語的部份，可透過收藏功能儲存首頁「每日一句」，建立專屬自己的新聞語彙資料庫。'
        },
        {
          level: 'C1',
          text:[
            '能聽懂各類各類主題的談話、辯論、演講、報導及節目等。​',
            '能閱讀各類不同主題、體裁的文章，包括報章雜誌、文學作品、專業期刊、學術著作及文獻等。​',
            '對各類主題均能流暢地表達看法、參與討論，能在一般會議或專業研討會中報告或發表意見等，並能撰寫專業性報告、論文、新聞報導。​'
          ],
          advice: '此階段的學員除自身領域外，也能瞭解跨領域、多重領域的英文內容，並能閱讀長篇文章，用英語談論不同領域的知識。故建議此階段學員從「新聞英語」主題中的「Focus全球新聞」和「新冠疫情專欄」兩門課，掌握最新時事，並試著單看影片，僅憑自身的英聽能力來理解通篇新聞的內容。若聽完後，發現有不解之處，再打開「文字」功能，重新播放，確認聽不清楚的部份，並收藏單字。寫作部份，若需要進行練習，可從「商業寫作」進階課程開始寫起。也可參與「直播教室」進行主題式增能課程。 '
        },
        {
          level: 'C2',
          text:[
            '能聽懂、讀懂各類主題及體裁的內容，理解程度與受過高等教育之母語人士相當。​',
            '能在各種不同場合以正確流利之英語表達看法；能適切引用文化知識、慣用語詞與專有名詞。​',
            '能撰寫不同性質的文章，如企劃報告、專業/學術性摘要、論文、新聞報導及時事評論等。對於各類主題均能有效完整地闡述並作深入探討。'
          ],
          advice: '此階段學員已具備菁英級母語人士能力，相當於英語學術界專業人士的程度。建議此階段的學員持續以「新聞英語」中的「科技創新」、「商業財經」、「深度旅遊」等主題，單看影片不開「文字」功能，維持英文聽力與字彙。也可參與「直播教室」進行主題式增能課程。'
        }
      ]
    }
  },
  props: {
    permissions: {
      type: String  ,
      required: false,
      default: 'N'
    }
  },
  computed: {
    examRole () {
      if (this.$store.state.auth.user.forcePreTest) {
        if (this.$store.state.auth.user.isFinishPreTest) {
          return true
        }else {
          return false
        }
      }else {
        return true
      }
    }
  },
  mounted () {
    // this.getCertificateDownload()
    this.getExamData()
    // this.getMonthlyExam()
    this.timer = setInterval(this.countdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async getExamData () {
      const { data } = await api.get(`/contents/exams?page=1&pageSize=10&type=${this.currentPage}`)
      this.exams = data.data
      this.cefr = data.data[0].cefr
    },
    async getExamId (id) {
      const { data } = await api.get(`/contents/exams/${id}`)
      return data.data.view_id
    },
    async getMonthlyExam () {
      const self = this
      const { data } = await api.get(`/contents/monthly_exam`)
      this.monthlyExam = data.data
      const beginTime = new Date(data.data.begin_at)
      this.time = (Date.parse(beginTime) - Date.now())/1000
    },
    async getMonthlyExamRecord () {
      const { data } = await api.get(`/contents/monthly_exams`)
      this.exams = data.data
    },
    async goExam (el) {
      if (this.currentPage === 'regular') {
        await this.getExamId(el.exam_id).then((val) => {
          if(val) {
            this.$router.push({ name: 'Examination', params: { examId: el.exam_id, viewId: val } })
          }
        } )
      }else {
        this.$router.push({ name: 'Examination', params: { examId: el.id, viewId: el.view_id } })
      }
    },
    goDetailAnswer (el) {
      this.$router.push({ name: 'DetailAnswer', params: { examId:el.id, viewId: el.view_id, recordId: el.record_id } })
    },
    doSubTitle (el) {
      this.currentPage = el
      if (el !== 'regular') {
        this.getExamData()
      } else {
        this.getMonthlyExamRecord()
      }
    },
    countdown() {
      this.time --
      if (this.time <= 0 ) {
        this.allowExam = true
        clearInterval(this.timer)
      }
    },
    // async getCertificateDownload () {
    //   const { data } = await api.get(`/certificate/toeic_mocking_test`)
    //   this.certificate = data.data.certificate

    // },
    async downloadCertificate (item) {
      return api.post(`/certificate/toeic_mocking_test?exam_id=${item.id}`, {} , {responseType: 'blob'}).then ((response) => {
        if (response) {
          let fileName = `Certificate_${item.id}`
          const url = URL.createObjectURL(new Blob([response.data],{
            type: 'image/png'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        } else {
          return Promise.reject()
        }
      })
    },
    timeStamp (s) {
      var time = parseInt(s) + "秒"
      if( parseInt(s)> 60) {
        var second = parseInt(s) % 60
        var min = parseInt(s / 60)
        time = min + "分" + second + "秒"
        if( min > 60 ){
          min = parseInt(s / 60) % 60;
          var hour = parseInt( parseInt(s / 60) /60 );
          time = hour + "小時" + min + "分" + second + "秒";

          if( hour > 24 ){
            hour = parseInt( parseInt(s / 60) /60 ) % 24;
            var day = parseInt( parseInt( parseInt(s / 60) /60 ) / 24 );
            time = day + "天" + hour + "小時" + min + "分" + second + "秒";
          }
        }
      }
      return time
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
table {
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
}
th {
  overflow:hidden;
  background: #428bca;
  border: 4px solid #fff;
  color: #fff;
  text-align: center !important;
  padding: 8px;
}
td {
  background-color: rgb(230, 230, 230);
  border: 4px solid #fff;
  text-align: center !important;
  padding: 8px;
}
.title-box {
  margin-bottom: 20px;
  border-top: #aaa solid 1px;
  border-bottom: #aaa solid 1px;
  padding: 10px 0;
  .title {
    color: #aaa;
    margin: 0 50px 0 0;
    cursor: pointer;
  }
  .active {
    color: black;
  }
}
.explanation{
  margin: 20px 0 50px 0;
  .red-text {
    font-weight: bold;
    color: red;
  }
  ul {
    list-style-type:decimal;
    padding: 0 20px;
  }
}
.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) "： ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-td {
    display: block !important;
    background-color: #04a2d6;
  }
  button  {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    text-align: left  !important;
  }
  .rwd-hide {
    display: none !important;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
  .alert-warning {
    display: none !important;
  }
}
</style>
