import { render, staticRenderFns } from "./DailySentence.vue?vue&type=template&id=1e66dc26&scoped=true&"
import script from "./DailySentence.vue?vue&type=script&lang=js&"
export * from "./DailySentence.vue?vue&type=script&lang=js&"
import style0 from "./DailySentence.vue?vue&type=style&index=0&id=1e66dc26&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e66dc26",
  null
  
)

export default component.exports