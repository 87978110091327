<template>
  <div class="container-fluid my-3 record" style="min-height:50vh">
    <div class="row">
      <div class="col-12">
        <div class="d-flex title-box">
          <div class="title" :class="{'active': currentPage==='Overview'}"  @click="goPage('Overview')">{{ $t('Personal.Record.View_all') }}</div>
          <div class="title" :class="{'active': currentPage==='Record'}" @click="goPage('Record')">{{ $t('Personal.Record.Details') }}</div>
          <!-- <div class="title" :class="{'active': currentPage==='Writing'}" @click="goPage('Writing')">{{ $t('Personal.Record.Writing') }}</div> -->
        </div>
        <div  class="my-2" v-if="currentPage==='Overview'">
          {{ $t('Personal.Record.Date_settings') }}:
          <date-picker valueType="format" v-model="range" type="date" range placeholder="請選擇日期區間"></date-picker>
          <div class="mt-2">
            班級分類：
            <select v-model="studentId" class="p-1">
              <option v-for="(item, index) in groupList" :key="index" :value="item.student_id">{{item.name}}</option>
            </select>
          </div>
          <div class="mt-2" style="float:right">
            <span>區間學習總時數：{{this.changeTime(this.sumData.time)}} </span>
            <span class="mx-3">通過單元測驗課數：{{this.sumData.pass_course}}課</span>
            <span>通過課後挑戰數：{{this.sumData.complete_practice}}次</span>
          </div>
        </div>
        <div v-if="currentPage==='Record'" class="my-2">
          {{ $t('Personal.Record.Course_categories') }}:
          <select v-model="selectedChannel" class="p-1">
            <option :value="0">全部</option>
            <option v-for="(item, index) in channels" :key="index" :value="item.id">{{item.name}}</option>
          </select>
          <div class="mt-2">
            班級分類：
            <select v-model="studentId" class="p-1">
              <option v-for="(item, index) in groupList" :key="index" :value="item.student_id">{{item.name}}</option>
            </select>
          </div>
          <div class="mt-2">
            {{ $t('Personal.Record.Date_settings') }}:
          <date-picker valueType="format" v-model="range" type="date" range placeholder="請選擇日期區間"></date-picker>
          </div>
        </div>
        <!-- <div  v-if="currentPage==='Writing'">
          <div class="mt-2">
            班級分類：
            <select v-model="studentId" class="p-1">
              <option v-for="(item, index) in groupList" :key="index" :value="item.student_id">{{item.name}}</option>
            </select>
          </div>
        </div> -->
      </div>
    </div>
    <div>
      <div v-if="currentPage=='Overview'">
        <div v-for="(item, index) in summaryRecord" :key="index">
          <div class="d-flex" v-if="item.category_name !== '雅思專區'&&item.category_name !== '寫作專區'">
            <table class="rwd-table">
              <tbody>
                <tr>
                  <th class="th-title" style="text-align:center;" rowspan="2">
                    <img :src="require('../assets/image/icon&btn/icon_title/'+item.category_name+'.png')" alt="">
                  </th>
                  <th>{{ $t('Personal.Info.Total_study_time') }}</th>
                  <th>{{ $t('Personal.Record.Number_of_completed_courses') }}</th>
                  <th>{{ $t('Personal.Record.Number_of_review_test_passed') }}</th>
                  <!-- <th class="pointer" style="text-align:center;background:#DB4D6D;" rowspan="2">開始</th>
                  <th class="pointer" style="text-align:center;background:#DB4D6D;" rowspan="2">詳解</th> -->
                </tr>
                <tr>
                  <td class="rwd-td" style="display:none" :data-th="item.category_name">
                  </td>
                  <td data-th="學習時數">
                    {{item.study_time ? item.study_time : 0}}
                  </td>
                  <td :data-th="$t('Personal.Record.Number_of_completed_courses')">
                    {{item.pass_course ? item.pass_course : 0}}
                  </td>
                  <td data-th="通過課後挑戰數">
                    {{item.complete_practice ? item.complete_practice : 0}}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <button type="button" style="width:80px" class="btn btn-danger m-1" @click="goPage(item.view_id)">開始</button> -->
            <!-- <button type="button" style="width:80px" class="btn btn-danger m-1">詳解</button> -->
          </div>
        </div>
      </div>
      <div v-if="currentPage=='Record'">
        <div v-for="(item, index) in recordInfo" :key="index">
          <div class="d-flex">
            <table class="rwd-table">
              <tbody>
                <tr>
                  <th class="th-title" style="text-align:center; width:350px!important;background-size:cover;" rowspan="2" :style="{ backgroundImage: 'url(' + item.banner + ')' }">
                    <div>
                      {{item.level1}} > {{item.level2}}
                    </div>
                    <h5>{{item.name}}</h5>
                  </th>
                  <th>{{ $t('Personal.Record.History') }}</th>
                  <th>{{ $t('Personal.Info.Total_study_time') }}</th>
                  <th>{{ $t('Personal.Record.Unit_test_score') }}</th>
                </tr>
                <tr>
                  <td class="rwd-td" style="display:none" data-th="課程">
                    {{item.name}}
                  </td>
                  <td data-th="最近學習時間">
                    {{moment(item.recent).format('YYYY/MM/DD HH:mm:ss') }}
                  </td>
                  <td data-th="學習時數">
                    {{item.time}}
                  </td>
                  <td data-th="單元測驗分數">
                    {{item.score? item.score: 0}}
                  </td>
                </tr>
              </tbody>
            </table>
            <button type="button" class="btn btn-danger m-1 p-3" @click="goLesson(item.id)">{{ $t('Home.Go_to_course') }}</button>
          </div>
        </div>
        <div v-if="recordInfo.length === 0" class="row justify-content-center align-items-center" style="height:200px">
          <div>
            <h4 class="my-auto" style="color:#8b8b8b">查無學習紀錄</h4>
          </div>
        </div>
        <pagination v-if="pageCount>1" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
      </div>
      <div v-if="currentPage=='Writing'">
        <div class="col-12">
          <div class="row">
            <div class="d-flex my-3">
              <h3 class="title pr-3">生活實用</h3>
            </div>
            <table class="rwd-table"  v-if="lifeData.length>0">
              <tr>
                <th style="width:50%">文章主題</th>
                <th style="width:20%">狀態</th>
              </tr>
              <tr v-for="(item, index) in lifeData" :key="index">
                <td data-th="文章主題">{{item.title}} {{item.title_en}}</td>
                <td data-th="狀態">
                  {{ item.user_date?moment(item.user_date).format('YYYY/MM/DD'):'' }}
                  <span  :class="{'approved': item.user_status ==='approved', 'denied': item.user_status === 'denied'}" >{{amendStatus(item.user_status)}}</span>
                  <a v-if="item.user_file !== null" download :href="item.user_file">
                    <img class="mx-2" src="../assets/image/icon&btn/icon_have_btn_function/btn_download.png" alt="">
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-12">
          <div class="row ">
            <div class="d-flex my-3">
              <h3 class="title pr-3">職場商用</h3>
            </div>
            <table class="rwd-table" v-if="bizData.length>0">
              <tr>
                <th style="width:50%">文章主題</th>
                <th style="width:20%">狀態</th>
              </tr>
              <tr v-for="(item, index) in bizData" :key="index">
                <td data-th="文章主題">{{item.title}} {{item.title_en}}</td>
                <td data-th="狀態">
                  {{ item.user_date?moment(item.user_date).format('YYYY/MM/DD'):'' }}
                  <span :class="{'approved': item.user_status ==='approved', 'denied': item.user_status === 'denied'}" >{{amendStatus(item.user_status)}}</span>
                  <a v-if="item.user_file !== null" download :href="item.user_file">
                    <!-- <img class="mx-2" src="../../assets/image/icon&btn/icon_have_btn_function/btn_download.png" alt=""> -->
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Pagination from './Pagination'
import api from '../store/api'
import moment from 'moment';
// import momentDurationFormatSetup from "moment-duration-format";

export default {
  name: 'Record',
  data () {
    return {
      currentPage: 'Overview',
      range: [ moment(new Date(new Date().getTime()- 6 * 24 * 3600 * 1000)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      channels: [],
      selectedChannel: 0,
      // month: [1,2,3,4,5,6,7,8,9,10,11,12],
      summaryRecord: null,
      recordInfo: null,
      sumData: {
        time: 0,
        complete_practice: 0,
        pass_course: 0
      },
      studentId: 0,
      groupList: [],
      lifeData: [],
      bizData: [],
      pageIndex: 1,
      pageSize: 10,
      pageCount: 0
    }
  },
  components: {
    Pagination,
    DatePicker
  },
  watch: {
    selectedChannel (val) {
      this.pageIndex = 1
      this.getRecordInfo()
    },
    range(val) {
      this.getSummaryRecord()
      this.getRecordInfo()
    },
    studentId (val) {
      this.getSummaryRecord()
      this.getRecordInfo()
      this.getWritingData()
    },
    currentPage (val) {
      if (val==='Writing') {
        this.getWritingData()
      } else if (val === 'Record') {
        this.getRecordInfo()
      } else {
        this.getSummaryRecord()
      }
    }
  },
  mounted () {
    this.getStudentId()
    this.getChannel()
    this.getGroupList()
  },
  methods: {
    goPage (el) {
      this.currentPage = el
    },
    async getWritingData () {
      this.lifeData = []
      this.bizData = []
      const params = {
        page: 1,
        pageSize: 26,
        student_id: this.studentId
      }
      const { data } = await api.get(`/writings`, { params })
      data.data.map((el => {
        if (el.category === '生活實用') {
          this.lifeData.push(el)
        } else if (el.category === '職場商用') {
          this.bizData.push(el)
        }
      }))
      this.lifeData.sort((a, b) => a.sort.localeCompare(b.sort))
      this.bizData.sort((a, b) => a.sort.localeCompare(b.sort))
    },
    async getSummaryRecord () {
      const params = {
        beginAt: this.range[0],
        endAt:  this.range[1],
      }
      const { data } = await api.get(`/records/students/${this.studentId}/learning_records`, { params })
      this.summaryRecord = data.data
      let sum = {
        time: 0,
        complete_practice: 0,
        pass_course: 0
      }
      data.data.forEach((el) => {
        sum.pass_course += el.pass_course === '-' ? 0 : el.pass_course
        sum.complete_practice += el.complete_practice ==='-' ? 0 : el.complete_practice
        sum.time += el.study_seconds
      })
      this.sumData = sum
    },
    async getStudentId () {
      const { data } = await api.get('/me')
      this.studentId = data.student_id
    },
    async getRecordInfo () {
      const params = {
        page: this.pageIndex,
        pageSize: this.pageSize,
        categoryId: this.selectedChannel,
        beginAt: this.range[0],
        endAt:  this.range[1]
      }
      const { data } = await api.get(`/records/students/${this.studentId}/learning_history`, { params })
      this.recordInfo = data.data
      this.pageCount = data.pageCount
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        return data.data
      }
    },
    async goLesson (id) {
      await this.getLessonId(id).then((val) => {
        this.$router.push({ name: 'CourseLesson', params: { lesson: val.id, courseId: val.sections[0].data.view_id||val.sections[0].id } })
      } )
    },
    async getChannel () {
      const { data } = await api.get('/contents/channels')
      let newData = data.data
      newData = newData.filter((el)=> {
        return el.name !== '寫作專區' && el.name !== '雅思專區'
      })
      this.channels = newData
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getRecordInfo()
    },
    async getGroupList () {
      const { data } = await api.get('/me/groups')
      this.groupList = data.data
    },
    amendStatus (el) {
      switch (el) {
        case 'waiting':
          return '未提交'
        case 'approved':
          return '已完成'
        case 'uploaded':
          return '批改中'
        case 'denied':
          return '退件'
      }
    },
    changeTime (value) {
      let secondTime = parseInt(value)
      let minuteTime = 0
      let hourTime = 0
      if (secondTime > 60) {
        minuteTime = parseInt(secondTime / 60)
        secondTime = parseInt(secondTime % 60)
        if (minuteTime > 60) {
          hourTime = parseInt(minuteTime / 60)
          minuteTime = parseInt(minuteTime % 60)
        }
      }
      let time = "" + parseInt(secondTime) + "秒"
      if (minuteTime > 0) {
        time = "" + parseInt(minuteTime) + "分" + time
      }
      if( hourTime > 0) {
        time = "" + parseInt(hourTime) + "小時" + time
      }
      return time
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/color.scss";
.record {
  text-align: left;
}
table {
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
}
th {
  overflow:hidden;
  // background: #428bca;
  border: 4px solid #fff;
  color: #fff;
  text-align: center !important;
  padding: 8px;
}
td {
  background-color: rgb(230, 230, 230);
  border: 4px solid #fff;
  text-align: center !important;
  padding: 8px;
}
.th-title {
  width: 220px;
  height: 120px;
  padding: 0 !important;
  position: relative;
  img {
    top:0;
    left: -5px;
    position: absolute;
    width: 105%;
    height: 100%;
  }
}

.title-box {
  margin-bottom: 20px;
  border-top: #aaa solid 1px;
  border-bottom: #aaa solid 1px;
  padding: 10px 0;
  .title {
    color: #aaa;
    margin: 0 50px 0 0;
    cursor: pointer;
  }
  .active {
    color: black;
  }
}
.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: $table-blue;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) "： ";
  font-weight: bold;
  display: inline-block;
  color: #000;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-td {
    display: block !important;
    background-color: #04a2d6;
  }
  // button  {
  //   display: none;
  // }
  .rwd-table th,
  .rwd-table td {
    text-align: left  !important;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
}
</style>
