import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    auth: {
      authorized: false,
      user: '',
      role: null,
      baseUrl: 'll-pro.liveabc.com'
    },
    breadcrumb: [],
    videoData: {},
    titleName: {},
    groupId: null,
    local: window.localStorage.getItem('locale')
  },
  mutations: {
    isLoading (state, value) {
      state.isLoading = value
    },
    login (state, value) {
      state.auth.authorized = true
      state.auth.user = value
      state.auth.baseUrl = 'll-pro.liveabc.com'
    },
    logout (state, value) {
      state.auth.authorized = false
      state.auth.user = ''
      state.auth.role = null
      state.auth.baseUrl = ''
    },
    setVideo (state, value) {
      state.videoData = value.sections.reverse()
      state.titleName = {
        name: value.name,
        name_en: value.name_en
      }
    },
    setBreadcrumb (state, value) {
      state.breadcrumb[0] = value
    },
    setLocal (state) {
      state.local = window.localStorage.getItem('locale')
    },
    setRole (state, value) {
      state.auth.role = value.role
    }
  },
  actions: {
    login: async ({ commit, dispatch }, user) => {
      const fileData = new window.FormData()
      fileData.append('email', user.email)
      fileData.append('password', user.password)
      fileData.append('device_name', 'web')
      const { data } = await api.post('/login', fileData)
      commit('login', data)
      return data
    },
    getUser: async ({ commit }) => {
      const { data } = await api.get('/me')
      commit('setRole', data)
    },
    logout: async ({ commit }) => {
      const { data } = await api.post('/logout')
      commit('logout', data)
    },
    getVideo: async ({ commit, dispatch }, id) => {
      const { data } = await api.get(`/contents/courses/${id}`)
      commit('setVideo', data.data)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      {
        key: 'livelearning',
        paths: [
          'auth'
          // 'superAdmin'
        ]
      }
    )
  ],
  getters: {
    isLoading: state => state.isLoading,
    authorized: state => state.auth.authorized,
    role: state => state.auth.role,
    videoData: state => state.videoData,
    titleName: state => state.titleName,
    local: state => state.local,
    authInfo: state => state.auth
  }
})
