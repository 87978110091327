<template>
  <div>
    <div class="banner">
      <div class="banner-box">
        <div>
          <h2>
            課程總覽
          </h2>
          <p>
            Overview
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row my-3">
        <div class="col-md-3">
          <div class="row pr-4">
            <div class="col-12">
              <div class="d-flex my-3">
                <h3 class="title pr-3">{{ $t('Course_Overview.cefr_level') }}</h3>
                <div class="line"></div>
              </div>
              <div class="d-flex flex-md-column">
                <div class="form-group form-check">
                  <input v-model="cefr" type="checkbox" value="A1" class="form-check-input">
                  <label class="form-check-label">A1<span class="rwd-hide">{{ $t('Course_Overview.A1') }}</span></label>
                </div>
                <div class="form-group form-check">
                  <input v-model="cefr" type="checkbox" value="A2" class="form-check-input">
                  <label class="form-check-label">A2<span class="rwd-hide">{{ $t('Course_Overview.A2') }}</span></label>
                </div>
                <div class="form-group form-check">
                  <input v-model="cefr" type="checkbox"  value="B1" class="form-check-input">
                  <label class="form-check-label">B1<span class="rwd-hide">{{ $t('Course_Overview.B1') }}</span></label>
                </div>
                <div class="form-group form-check">
                  <input v-model="cefr" type="checkbox" value="B2" class="form-check-input">
                  <label class="form-check-label">B2<span class="rwd-hide">{{ $t('Course_Overview.B2') }}</span></label>
                </div>
                <div class="form-group form-check">
                  <input v-model="cefr" type="checkbox" value="C1" class="form-check-input">
                  <label class="form-check-label">C1<span class="rwd-hide">{{ $t('Course_Overview.C1') }}</span></label>
                </div>
                <div class="form-group form-check">
                  <input v-model="cefr" type="checkbox" value="C2" class="form-check-input">
                  <label class="form-check-label">C2<span class="rwd-hide">{{ $t('Course_Overview.C2') }}</span></label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex my-3">
                <h3 class="title pr-3">{{ $t('Course_Overview.course_category') }}</h3>
                <div class="line"></div>
              </div>
              <div class="d-flex flex-md-column">
                <div class="form-group form-check">
                  <input type="checkbox" v-model="categories" value="1" class="form-check-input">
                  <label class="form-check-label">{{ $t('Course_Overview.life') }}</label>
                </div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="categories" value="3" class="form-check-input">
                  <label class="form-check-label">{{ $t('Course_Overview.biz') }}</label>
                </div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="categories" value="2" class="form-check-input">
                  <label class="form-check-label">{{ $t('Course_Overview.news') }}</label>
                </div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="categories" value="4" class="form-check-input">
                  <label class="form-check-label">{{ $t('Course_Overview.toeic') }}</label>
                </div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="categories" value="5" class="form-check-input">
                  <label class="form-check-label">{{ $t('Course_Overview.learning_resources') }}</label>
                </div>
              </div>
            </div>
            <!-- <div class="col-12">
              <div div class="d-flex my-3">
                <h3 class="title pr-3">課程內容</h3>
                <div class="line"></div>
              </div>
              <div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="has" value="video" class="form-check-input">
                  <label class="form-check-label">講解影片</label>
                </div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="has" value="livecourse" class="form-check-input">
                  <label class="form-check-label">互動課程</label>
                </div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="has" value="practice" class="form-check-input">
                  <label class="form-check-label">單元測驗</label>
                </div>
              </div>
            </div> -->
            <div class="col-12">
              <div div class="d-flex my-3">
                <h3 class="title pr-3">{{ $t('Course_Overview.unit_test') }}</h3>
                <div class="line"></div>
              </div>
              <div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="pass" value="1" class="form-check-input">
                  <label class="form-check-label">{{ $t('Course_Overview.passed') }}</label>
                </div>
                <div class="form-group form-check">
                  <input type="checkbox" v-model="pass" value="-1"  class="form-check-input">
                  <label class="form-check-label">{{ $t('Course_Overview.unpassed') }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="row d-flex justify-content-between">
            <div class="d-flex rwd-hide align-items-center">
              <div class="mx-3 d-flex">
                <img src="../assets/image/pic/icon_class_subject_commentary.png" alt="">
                <div class="my-auto" style="white-space:nowrap;">{{ $t('Course.Description_video') }}</div>
              </div>
              <div class="mr-3 d-flex">
                <img src="../assets/image/pic/icon_class_subject_teaching.png" alt="">
                <div class="my-auto" style="white-space:nowrap;">{{ $t('Course.Interactive_learning') }}</div>
              </div>
              <div class="mr-3 d-flex">
                <img src="../assets/image/pic/icon_class_subject_text.png" alt="">
                <div class="my-auto" style="white-space:nowrap;">{{ $t('Course.Unit_test') }}</div>
              </div>
              <div class="mr-3 d-flex">
                <img src="../assets/image/pic/icon_class_time_G.png" alt="">
                <div class="my-auto">{{ $t('Course.Record_study_time') }}</div>
              </div>
            </div>
            <div class="search d-flex ml-3 my-2">
              <input v-model="search" v-on:keyup.enter="doSearch()" maxlength="15" type="text" class="form-control" placeholder="Search">
              <button type="button" @click="doSearch()" class="btn btn-primary mx-2">Search</button>
            </div>
          </div>
          <div class="row mt-2" v-if="lessonData.length>0">
            <div v-for="item in lessonData" :key="item.id" class="col-lg-4 col-md-6 col-sm-12 card-box" >
              <div v-if="item.type === 'digital'" class="card my-2 pointer" @click="goCoursePage(item.id)">
                <div class="picture image-box pointer">
                  <img class="tag" v-if="!item.is_favorite" src="../assets/image/pic/btn_tag_off.png" alt="" @click.stop="addCollections(item)">
                  <img class="tag" v-else src="../assets/image/pic/btn_tag_on.png" alt="" @click.stop="removeCollections(item)">
                  <span class="label">{{item.is_new?'New':''}}</span>
                  <img :src="item.cover" class="card-img-top cover">
                </div>
                <div class="card-body" style="background:	#F5F5F5">
                  <div class="card-title" style="height:110px">
                    <div class="content">
                      <h5>{{item.name}}</h5>
                    </div>
                    <div class="content">
                      <p>{{item.name_en}}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-stretch">
                    <div class="my-auto">
                      <span class="badge badge-danger">{{item.cefr}}</span>
                      <img v-if="item.has_detail_video" src="../assets/image/pic/icon_class_subject_commentary.png" alt="">
                      <img v-if="item.has_live_course" src="../assets/image/pic/icon_class_subject_teaching.png" alt="">
                      <img v-if="item.has_practice" src="../assets/image/pic/icon_class_subject_text.png" alt="">
                      <img src="../assets/image/pic/icon_class_time_G.png" alt=""></img>
                    </div>
                    <img v-if="item.is_pass" class="my-auto" style="width:25px; height:25px" src="../assets/image/pic/icon_class_video_complete.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-auto">
              <pagination v-if="pageCount>1 && lessonData.length>0" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12 default-info">
              <h5>
                目前找到0筆資料，請改以其他關鍵字或篩選功能搜尋課程。
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination.vue'
import api from '../store/api'
export default {
  name: 'Search',
  components: {
    Pagination
  },
  computed: {

  },
  watch: {
    cefr (val ) {
      this.pageIndex = 1
      this.$router.push({
        name: 'Search',
        query:{
          cefr: val.join(',') || null,
          pass: this.$route.query.pass,
          type: this.$route.query.type,
          q: this.$route.query.q,
          page: 1
        }
      })
    },
    categories (val) {
      this.pageIndex = 1
      this.$router.push({
        name: 'Search',
        query: {
          cefr: this.$route.query.cefr,
          pass: this.$route.query.pass,
          type: val.join(',') || null,
          q: this.$route.query.q,
          page: 1
        }
      })
    },
    pass (val) {
      this.pageIndex = 1
      this.$router.push({
        name: 'Search',
        query: {
          cefr: this.$route.query.cefr,
          pass: val.join(',') || null,
          type: this.$route.query.type,
          q: this.$route.query.q,
          page: 1
        }
      })
    }
  },
  data () {
    return {
      search: this.$route.query.q,
      cefr: this.$route.query.cefr ? this.$route.query.cefr.split(',') : [],
      has: [],
      categories: this.$route.query.type ? this.$route.query.type.split(',') : [],
      pass: this.$route.query.pass ? this.$route.query.pass.split(',') : [],
      lessonData: {},
      pageIndex: this.$route.query.page,
      pageCount: 0,
      pageSize: 12
    }
  },
  mounted () {
    this.scrollTop()
    this.getLessonInfo()
  },
  methods: {
    async getLessonInfo () {
      const params = {
        cefr: this.cefr.join(','),
        categories: this.categories.join(','),
        pass: this.pass.join(','),
        // has: this.has.join(','),
        // sort: 'public_at',
        orderBy : 'desc',
        q: this.search,
        page: this.pageIndex,
        pageSize: this.pageSize
      }
      const { data } = await api.get(`/contents/courses`, { params })
      this.lessonData = data.data
      this.pageIndex = data.pageIndex
      this.pageCount = data.pageCount
    },
    toPage (toPage) {
      this.$router.push({
        name: 'Search',
        query:{
          pass: this.$route.query.pass,
          cefr: this.$route.query.cefr,
          type: this.$route.query.type,
          q: this.$route.query.q,
          page: toPage
        }
      })
    },
    async addCollections (el) {
      // el.banner = this.info.banner
      await this.getLessonId(el.id).then((val) => {
        try {
          const json = {
            banner: val.package_course.banner,
            view_id: val.sections[0].data.view_id||val.sections[0].id
          }
          const fileData = new window.FormData()
          fileData.append('json', JSON.stringify(json))
          fileData.append('view_id', val.sections[0].data.view_id||val.sections[0].id)
          api.post(`collections/course/${el.id}`, fileData).then((res)=> {
            if (res.status === 200) {
              this.getLessonInfo()
            }
          })
        } catch (error) {
          console.error(error.response.data)
        }
      })
    },
    async removeCollections (el) {
      await this.getLessonId(el.id).then((val) => {
        try {
          api.delete(`collections/course/${el.id}`).then((res)=> {
            if (res.status === 200) {
              this.getLessonInfo()
            }
          })
        } catch (error) {
          console.error(error.response.data)
        }
      })
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        return data.data
      }
    },
    async goCoursePage (id) {
      await this.getLessonId(id).then((val) => {
        this.$router.push({ name: 'CourseLesson', params: { lesson: val.id, courseId: val.sections[0].data.view_id||val.sections[0].id } })
      } )
    },
    doSearch() {
      this.pageIndex = 1
      this.$router.push({
        name: 'Search',
        query:{
          cefr: this.$route.params.cefr,
          type: this.$route.params.type,
          pass: this.$route.params.pass,
          q: this.search,
          page: this.pageIndex
        }
      })
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";
  @import "../assets/styles/custom.scss";
  .container {
    text-align: left;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    // width: 100%;
    width: 98%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .banner {
    height: 250px;
    background-image: url('../assets/image/home/banner_bg_biztalk_top.jpg');
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center;
    background-size: cover;
    .banner-box {
      padding: 60px 0 10px 0;
      h2 {
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }
      p {
        color: rgb(47, 161, 255);
        font-weight: 600;
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }
  .form-check {
    white-space: nowrap;
  }
  .picture {
    position:relative;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    background-color: #000;
    .cover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 130%;
      height: 130%;
      object-fit: contain;
      transition:all 0.2s ease;
    }
    .tag {
      width: 20px;
      position: absolute;
      top: 5%;
      left: 5%;
      z-index: 50;
    }
    .label {
      background-color: red;
      padding: 0 30px 0 60px ;
      color: rgb(255, 255, 255);
      font-weight: bolder;
      font-size: 1rem;
      transform: rotate(35deg);
      position: absolute;
      top: 0%;
      right: -8%;
      z-index: 50;
    }
  }
  .picture .cover:hover {
    transform: scale(1.05)  translate(-50%,-50%);
    filter:opacity(.8);
  }
  .course-info {
    text-align: left;
    // padding: 20px 50px;
  }
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $gray-color;
    div {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      color: white;
    }
  }
  .card-title {
    border-bottom: 1px solid #bbb;
    text-align: left;
    .content {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    h5 {
      font-size: 1.2rem;
      font-weight: bolder;
    }
    p {
      font-size: .8rem;
    }
  }
  .default-info {
    height: 50vh;
    h5 {
      width: 80%;
      color: rgb(122, 122, 122);
      font-size: 1.5rem;
      @include center();
    }
  }
  @media all and (max-width: 767px) {
    .rwd-hide {
      display: none !important;
    }
    .form-group {
      margin: auto;
    }
  }
</style>
