<template>
  <div>
    <div class="container my-3" style="min-height:50vh">
      <div class="row">
        <div class="col-12">
          <div class="d-flex title-box">
            <div class="title" :class="{'active': type==='daily'}"  @click="goPage('daily')">{{ $t('Home.Daily_English') }}</div>
            <div class="title" :class="{'active' : type==='vocabulary'}"  @click="goPage('vocabulary')">{{ $t('Personal.Collection.Vocabulary') }}</div>
            <div class="title" :class="{'active': type==='lesson'}" @click="goPage('lesson')">{{ $t('Personal.Collection.Lesson') }}</div>
            <div class="title" :class="{'active' : type==='news'}" @click="goPage('news')">{{ $t('Home.World_News') }}</div>
            <div class="title" :class="{'active' : type==='english'}" @click="goPage('english')">{{ $t('Home.Lets_play_in_English') }}</div>
            <div class="title" :class="{'active' : type==='hotWord'}" @click="goPage('hotWord')"> 最新話題字 </div>
            <div class="title" :class="{'active' : type==='travelTaiwan'}" @click="goPage('travelTaiwan')"> 遊臺灣學英文 </div>
          </div>
        </div>
      </div>
      <div class="row my-3" v-if="type==='daily'">
        <div v-for="(item, index) in collections" :key="index" class="content-box col-12 my-2">
          <div :key="index" class="row align-items-center" style="min-height:100px">
            <div class="d-flex col-md-2">
              <div>
                <img @click="removeAlert(apiType,item.data.id)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
              </div>
              <div class="my-auto">
                <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                  {{moment(item.json.publish_at).format('YYYY/MM/DD') }}
                </h5>
              </div>
            </div>
            <div class="col-md-8 my-2">
              <p class="p-0 my-1">{{item.name}}</p>
              <p class="p-0 my-1">{{item.json.local}}</p>
            </div>
            <div class="col-md-2" style="text-align:right">
              <img class="mx-1 pointer" @click="playAudio(item.id, 'daily-sentences')" src="../assets/image/pic/icon_voice.png" alt="">
              <!-- <img class="mx-1 pointer" src="../assets/image/pic/icon_explanation.png" alt=""> -->
            </div>
          </div>
          <div v-if="item.json.audio_url">
            <audio :id="`daily-sentences${item.id}`">
              <source :src="item.json.audio_url" type="audio/mp3">
            </audio>
          </div>
        </div>
      </div>
      <div class="row my-3" v-if="type==='vocabulary'">
        <div class="container">
          <div class="row">
            <div class="col">
              <div v-for="(item, index) in collections" :key="index" class="my-3">
                <div :key="index" class="row align-items-center content-box" style="min-height:100px">
                  <div class="col-md-1">
                    <div class="my-auto">
                      <img @click="removeAlert(apiType,item.json.params.courseId,item)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="my-auto mx-2 d-flex pointer" @click="playAudio(item.id, 'word')">
                      <p class="p-0 my-1 main-title"> {{item.name}} </p>
                      <img class="my-auto mx-2 pointer" src="../assets/image/pic/icon_voice.png" alt="">
                    </div>
                  </div>
                  <div class="col-md-2 d-flex">
                    <div class="my-auto mx-2 pointer">
                      <p class="p-0 my-1">{{item.json.vocabulary.pronouncing}}</p>
                    </div>
                  </div>
                  <div class="col-md-5 d-flex">
                    <div class="my-auto mx-2">
                      <p class="p-0 my-1">{{item.json.vocabulary.partOfSpeech}} {{item.json.vocabulary.name_i18N[0].value}}</p>
                    </div>
                  </div>
                  <div class="col-md-2" style="text-align:right">
                    <button type="button" @click="goLesson(item)" class="btn btn-danger">來源課程</button>
                  </div>
                </div>
                <div v-if="item.json.vocabulary.audio">
                  <audio :id="`word${item.id}`">
                    <source :src="item.json.vocabulary.audio" type="audio/mp3">
                  </audio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3" v-if="type==='lesson'">
        <div v-for="(item, index) in collections" :key="index" class="col-12" >
          <div :key="index" class="row align-items-center my-2">
            <div class="col-md-6 d-flex align-content-center m-0 p-0" style="height:80px;">
              <div class="content-box">
                <img @click="removeAlert(apiType,item.data.id)" class="pointer my-3" src="../assets/image/pic/btn_function_mark_on.png" alt="">
              </div>
              <div class="picture image-box pointer ml-2" style="width:100%;" >
                <img :src="item.data.banner" alt="">
                <h5 class="p-0 my-0">
                  {{item.data.name}}
                </h5>
              </div>
            </div>
            <div class="col-md-6 d-flex content-box my-2" style="height:80px">
              <div class="my-auto mr-2">
                <span class="badge badge-danger">{{item.data.cefr}}</span>
              </div>
              <div class="my-auto" style="width:75%">
                <p class="p-0 my-0">{{item.data.name_en}}</p>
              </div>
              <div class="my-auto">
                <button type="button" @click="goLesson(item)" class="btn btn-danger">進入課程</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3" v-if="type==='news'">
        <div class="container">
          <popUp v-if="toggle" v-on:doClose="close">
            <div class="pop-title" slot="title">
              <h4>{{moment(dailyNews.created_at).format('YYYY/MM/DD') }}</h4>
              <h3>{{dailyNews.name}}</h3>
              <h4>{{dailyNews.name_en}}</h4>
            </div>
            <div slot="info" class="info-main" >
              <div class="video-box">
                <live-player ref="liveplayer" :moduleEnable="['video', 'article', 'ebook', 'text']" :liveData="newsData" v-if="newsData.modules.length > 0 " />
              </div>
            </div>
          </popUp>
          <div class="row" v-if="type==='news'">
            <div class="col">
              <div v-for="(item, index) in collections" :key="index" class="my-3">
                <div :key="index" class="row align-items-center content-box" style="min-height:100px">
                  <div class="d-flex col-md-2">
                    <div class="my-auto">
                      <img @click="removeAlert(apiType,item.data.id)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                    </div>
                    <div class="my-auto">
                      <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                        {{moment(item.json.public_at).format('YYYY/MM/DD') }}
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-7 d-flex">
                    <img style="width:150px" :src="item.data.cover" alt="">
                    <div class="my-auto mx-2">
                      <p class="p-0 my-1 main-title"> {{item.data.name}}</p>
                      <p class="p-0 my-1">{{item.data.name_en}}</p>
                    </div>
                  </div>
                  <div class="col-md-3" style="text-align:right">
                    <button type="button" @click="getNewsData(item.json.view_id)" class="btn btn-danger px-4 mx-auto">More</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <popUp v-if="toggle && videoData" v-on:doClose="close">
          <div class="pop-title" slot="title">
            <h6>{{moment(videoData.json.public_at).format('YYYY/MM/DD') }}</h6>
            <h5>{{videoData.data.name}}</h5>
            <h6>{{videoData.data.name_en}}</h6>
          </div>
          <div slot="info" class="info-main" >
            <div class="video-box" v-if="videoData">
              <player style="width:100%;background:black;" class="m-auto my-3" :url="videoData.json.url"/>
            </div>
          </div>
        </popUp>
        <div class="row" v-if="type ==='english'">
          <div class="col">
            <div v-for="(item, index) in collections" :key="index" class="my-3">
              <div :key="index" class="row align-items-center content-box" style="min-height:100px">
              <div class="d-flex col-md-2">
                <div class="my-auto">
                  <img @click="removeAlert(apiType,item.data.id)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                </div>
                <div class="my-auto">
                  <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                    {{moment(item.json.public_at).format('YYYY/MM/DD') }}
                  </h5>
                </div>
              </div>
              <div class="col-md-7 d-flex">
                <div class="my-auto mx-2 d-flex">
                  <img style="width:150px" :src="item.data.cover" alt="">
                  <div class="my-auto mx-2">
                    <p class="p-0 my-1 main-title">{{item.data.name}}</p>
                    <p class="p-0 my-1">{{item.data.name_en}}</p>
                  </div>
                </div>
              </div>
                <div class="col-md-3" style="text-align:right">
                  <button type="button" @click="openVideo(item)" class="btn btn-danger mx-auto px-4">More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <popUp v-if="toggle && videoData" v-on:doClose="close">
          <div class="pop-title" slot="title">
            <h6>{{moment(videoData.json.public_at).format('YYYY/MM/DD') }}</h6>
            <h5>{{videoData.data.name}}</h5>
            <h6>{{videoData.data.name_en}}</h6>
          </div>
          <div slot="info" class="info-main" >
            <div class="video-box" v-if="videoData">
              <player style="width:100%;background:black;" class="m-auto my-3" :url="videoData.json.url"/>
            </div>
          </div>
        </popUp>
        <div class="row" v-if="type ==='travelTaiwan'">
          <div class="col">
            <div v-for="(item, index) in collections" :key="index" class="my-3">
              <div :key="index" class="row align-items-center content-box" style="min-height:100px">
              <div class="d-flex col-md-2">
                <div class="my-auto">
                  <img @click="removeAlert(apiType,item.data.id)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                </div>
                <div class="my-auto">
                  <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                    {{moment(item.json.public_at).format('YYYY/MM/DD') }}
                  </h5>
                </div>
              </div>
              <div class="col-md-7 d-flex">
                <div class="my-auto mx-2 d-flex">
                  <img style="width:150px" :src="item.data.cover" alt="">
                  <div class="my-auto mx-2">
                    <p class="p-0 my-1 main-title">{{item.data.name}}</p>
                    <p class="p-0 my-1">{{item.data.name_en}}</p>
                  </div>
                </div>
              </div>
                <div class="col-md-3" style="text-align:right">
                  <button type="button" @click="openVideo(item)" class="btn btn-danger mx-auto px-4">More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <popUp v-if="toggle && videoData" v-on:doClose="close">
          <div class="pop-title" slot="title">
            <h6>{{moment(videoData.json.public_at).format('YYYY/MM/DD') }}</h6>
            <h5>{{videoData.data.name}}</h5>
            <h6>{{videoData.data.name_en}}</h6>
          </div>
          <div slot="info" class="info-main" >
            <div class="video-box" v-if="videoData">
              <player style="width:100%;background:black;" class="m-auto my-3" :url="videoData.json.url"/>
            </div>
          </div>
        </popUp>
        <div class="row" v-if="type ==='hotWord'">
          <div class="col">
            <div v-for="(item, index) in collections" :key="index" class="my-3">
              <div :key="index" class="row align-items-center content-box" style="min-height:100px">
              <div class="d-flex col-md-2">
                <div class="my-auto">
                  <img @click="removeAlert(apiType,item.data.id)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                </div>
                <div class="my-auto">
                  <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                    {{moment(item.json.public_at).format('YYYY/MM/DD') }}
                  </h5>
                </div>
              </div>
              <div class="col-md-7 d-flex">
                <div class="my-auto mx-2 d-flex">
                  <img style="width:150px" :src="item.data.cover" alt="">
                  <div class="my-auto mx-2">
                    <p class="p-0 my-1 main-title">{{item.data.name}}</p>
                    <p class="p-0 my-1">{{item.data.name_en}}</p>
                  </div>
                </div>
              </div>
                <div class="col-md-3" style="text-align:right">
                  <button type="button" @click="openVideo(item)" class="btn btn-danger mx-auto px-4">More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center" style="height:200px"  v-if="collections">
        <div class="col" v-if="collections.length===0">
          <h4 class="my-auto" style="color:#8b8b8b">您還沒有收藏紀錄</h4>
        </div>
      </div>
    </div>
    <div v-if="collections">
      <pagination v-if="pageCount>1 && collections.length>0" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import Player from 'live-player/dist/Player'
import LivePlayer from 'live-player'
import PopUp from '@/components/PopUp.vue'
import Pagination from '@/components/Pagination.vue'
// import libraryApi from '../store/library/api'
import api from '../store/api'
export default {
  name: 'Collections',
  data () {
    return {
      type: 'daily',
      collections: null,
      dailyNews: null,
      toggle: false,
      videoData: null,
      pageIndex: 1,
      pageCount: 0
    }
  },
  components: {
    LivePlayer,
    Player,
    PopUp,
    Pagination
  },
  computed: {
    apiType () {
      switch (this.type) {
        case 'lesson':
          return 'course'
        case 'vocabulary':
          return 'word'
        case 'daily':
          return 'daily_sentence'
        case 'news':
          return 'daily_news'
        case 'english':
          return 'daily_english'
        case 'hotWord':
          return 'hot_word'
        case 'travelTaiwan':
          return 'travel_taiwan'
      }
      return null
    }
  },
  mounted () {
    this.getCollections(this.apiType)
  },
  watch: {
    apiType (val) {
      this.collections = null
      if (val === 'daily_news') {
        this.getNews()
      }
      this.getCollections(val)
    }
  },
  methods: {
    goPage (el) {
      this.pageIndex = 1
      this.type = el
    },
    goLesson (el) {
      if (this.type === 'vocabulary') {
        this.$router.push({ name: 'CourseLesson', params: { lesson: el.json.params.lesson, courseId: el.json.params.courseId } })
      }else {
        this.$router.push({ name: 'CourseLesson', params: { lesson: el.data.id, courseId: el.json.view_id } })
      }
    },
    async getCollections (type) {
      try {
        const { data } = await api.get(`collections/${type}?page=${this.pageIndex}&pageSize=10`)
        this.collections = data.data
        this.pageCount = data.pageCount
      } catch (error) {
        console.error(error.response.data)
      }
    },
    async getNews() {
      const params = {
        page: this.pageIndex,
        pageSize: 10,
        type: 'daily_news'
      }
      const { data } = await api.get('/contents/courses/', { params })
      // this.pageCount = data.pageCount
      this.dailyNews = data.data
    },
    async getNewsData (id) {
      // const { data } = await libraryApi.get(`/v1/livelibrary/Lesson/${id}`)
      const { data } = await api.get(`/contents/lesson/${id}`)
      if (data.status === 'OK') {
        this.newsData = data.data
        this.toggle = true
      }
    },
    removeAlert (type, id, el) {
      let self = this
      self.$swal({
        title: '確認刪除?',
        showCancelButton: true,
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        closeOnConfirm: false
      }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          self.removeCollections(type, id, el)
        }
      })
    },
    async removeCollections (type, id, el) {
      if (type === 'word') {
        try {
          const config = {
            data: {
              word: el.name
            }
          }
          await api.delete(`collections/${type}/${id}`, config)
          this.getCollections(this.apiType)
        } catch (error) {
          // console.error(error.response.data)
        }
      } else {
        try {
          await api.delete(`collections/${type}/${id}`)
          this.getCollections(this.apiType)
        } catch (error) {
          console.error(error.response.data)
        }
      }
    },
    // playAudio (id, type) {
    //   this.isPlay = id
    //   this.playAudio(id, type)
    // },
    playAudio (id, type) {
      let audio = document.getElementById(`${type}${id}`)
      this.pauseAll()
      audio.load()
      audio.play()
    },
    pauseAll () {
      let audios = document.getElementsByTagName('audio')
      const self = this
      const allAudios = []
      allAudios.forEach.call(audios, function (i) {
        i.currentTime = 0
        i !== self && i.pause()
      })
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getCollections(this.apiType)
      this.pauseAll()
    },
    close (close) {
      this.toggle = close
      this.videoData = null
    },
    openVideo (el) {
      this.videoData = el
      this.toggle = true
    },
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    height: 300px;
    background-image: url('../assets/image/pic/pic_blur_bg.jpg');
    // padding: 100px;
    // width: 100vw;
  }
  .title-box {
    margin-bottom: 20px;
    border-top: #aaa solid 1px;
    border-bottom: #aaa solid 1px;
    padding: 10px 0;
    .title {
      color: #aaa;
      margin: 0 50px 0 0;
      cursor: pointer;
    }
    .active {
      color: black;
    }
  }
  .picture {
    max-height: 80px;
    min-height: 80px;
    position:relative;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition:all 0.2s ease;
    }
    h5 {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .content-box {
    height: 100%;
    background-color: rgb(230, 230, 230);
    text-align: left;
    padding: 8px;
  }
  table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    min-width: 500px;
  }
  td {
    background-color: rgb(230, 230, 230);
    border: 4px solid #fff;
    text-align: left;
    padding: 8px;
  }
  .main-title {
    font-size:1.2rem;
    font-weight: bolder;
  }
  .daily-sentence {
    // text-align: left;
    h5 {
      font-weight: bold;
    }
  }
  .swal2-icon.swal2-warning {
    border-color: red !important;
    color: red !important;
  }
</style>
