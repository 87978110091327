<template>
  <footer class="footer">
    <div class="container" :class="{'footer-rwd': this.$route.name === 'CourseLesson'}">
      <div class="row py-5">
        <div class="row justify-content-around col-md-8">
          <div class="col-sm-3 col-6">
            <h4>幫助</h4>
            <!-- <p>常見問題</p>
            <p>免費試用</p>
            <p>會員專屬加購</p> -->
            <p>企業諮詢</p>
          </div>
          <div class="col-sm-3 col-6">
            <h4>
              相關連結
            </h4>
            <p>
              <a target="_blank" href="https://www.facebook.com/biz.tw/">FB</a>
            </p>
            <p>
              <a target="_blank" href="https://www.instagram.com/liveabc.official/">IG</a>
            </p>
            <p>
              <a target="_blank" href="https://store.liveabc.com/">官方網站</a>
            </p>
          </div>
        <div class="col-sm-6 mt-3 mt-sm-0">
          <h4>
            客戶服務
          </h4>
          <p>
            服務電話: (02)2578-2626#219
          </p>
          <p>
            客服時間: 週一至週五 09:00~18:00
          </p>
          <p>
            客服信箱: livelearningpro@liveabc.com
          </p>
          <p>
            Copyright©Hebron Soft Limited. All Rights Reserved.
          </p>
          </div>
        </div>
        <div class="footer-title col-md-4 m-auto text-center">
          <h2>LiveLearning Pro</h2>
          <div style="height:180px;">
            <img style="width:150px" src="../assets/PRO QR-code.png" alt="">
          </div>
          <select v-model="locale" @change="setLocale(locale)">
            <option value="en">English</option>
            <option value="tw">繁體中文</option>
            <!-- <option value="cn">简体中文</option> -->
          </select>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// import store from '../store';
export default {
  name: 'Footer',
  data () {
    return {
      locale: window.localStorage.getItem('locale') || 'tw'
    }
  },
  mounted () {
  },
  methods: {
    setLocale (locale) {
      window.localStorage.setItem('locale', locale || 'tw')
      this.locale = locale
      this.$store.commit("setLocal")
      this.scrollTop()
      return window.history.go(0)
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/color.scss";
@import "../assets/styles/custom.scss";
  .footer {
    background-color: $main-color;
    min-height: 150px;
    color: $white-color;
    position: relative;
    .footer{
      text-align: left;
    }
    h4 {
      text-align: left;
      font-weight: bolder;
    }
    p {
      text-align: left;
    }
    .footer-title {
      h3 {
        @include text(5rem, .2rem, white);
      }
    }
  }
  a,a:hover {
    color: #fff;
  }
  @media screen and (max-width: 600px) {
    .footer-rwd {
      display: none;
    }
  }
</style>
