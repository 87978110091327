<template>
  <div class="exam">
    <div v-if="examData">
      <Exam :isDebugMode="true" :locales="'zh_cn'" style="text-align:left !important" :liveData="examData"/>
    </div>
  </div>
</template>

<script>
import api from '../store/api'
import Exam from 'live-player/dist/Exam';
export default {
  name: 'Examination',
  data () {
    return {
      examData: null
    }
  },
  components: {
    Exam
  },
  mounted () {
    this.getExam(this.$route.params.viewId)
    this.scrollTop()
  },
  methods: {
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    async getExam (id) {
      const { data } = await api.get(`/liveabc/exams/${id}`)
      if (data.status === 'OK') {
          this.examData = data.data
        }
      }
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/color.scss";
  .exam {
    min-height: 100vh;
  }
</style>
