<template>
  <div>
    <div class="banner">
      <div class="banner-box d-flex" >
        <div class="title-box">
          <div class="text-box">
          </div>
        </div>
        <div class="content-box" style="width:50%; height:60vh; padding:10vh;">
          <div class="p-3" style="height:100%;background:rgba(255, 255, 255, 0.9);text-align:left">
            <p style="height:70%">
              托业专区提供托业考试TOEIC（Test of English of International Communication）应考准备相关丰富学习资源。除了考试简介，更有托业必考单词、常考语法解题教学视频与在线模拟测验试题，供学员多元练习。不仅教您如何解题，更可熟悉测验中常见的出题情境与必备词汇，掌握各题型应试策略。
            </p>
            <div style="text-align:center">
              <button type="button" @click="goAnchor('practice')" class="btn btn-danger btn-lg btn-sm" style="font-size:1rem">立刻进入托业模考​</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="d-md-flex justify-content-between my-4 anctors">
        <div class="my-2">
          <button type="button" @click="goAnchor('introduction', -100)" class="btn btn-danger m-auto" style="width:100%">考试简介</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('content', -100)" class="btn btn-danger m-auto" style="width:100%">題型內容</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('situation', -100)" class="btn btn-danger m-auto" style="width:100%">考试主题</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('guide', -100)" class="btn btn-danger m-auto" style="width:100%">能力对标​</button>
        </div>
        <div class="my-2 rwd-hide">
          <button type="button" @click="goAnchor('practice')" class="btn btn-danger m-auto" style="width:100%">托业模考</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="introduction">
            <h3 class="title pr-3"> 考试简介</h3>
            <div class="line"></div>
          </div>
          <div>
            托业英语考试（TOEIC®）全名为Test of English for International Communication，是美国教育考试服务中心（ETS）开发的, 专门针对在国际工作环境中使用英语交流的人士的英语能力测评标准。，全球每年有超过700万人次报考。 托业考试是世界知名的商务和职业英语考试全球160个国家施测，至少为1万4千个以上的企业客户、教育单位及政府机构所采用。在中国，政府部门，大量的国企、跨国企业及机构都将托业考试成熟运用于员工招聘、海外派遣、员工培训和内部选拔成绩不仅具备信度与效度，也具有国际流通度，足以体现考生在实际沟通情境中的英语文能力。 TOEIC®Listening and Reading Test本身并没有所谓的「通过」或「不通过」，而是客观地将受测者的能力以听力5～495分、阅读5～495分、总分10～990分的指标呈现，受测者也可以自评现在的英语能力，进而设定学习的目标分数。
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="content">
            <h3 class="title pr-3">题型内容</h3>
            <div class="line"></div>
          </div>
          <div class="row my-3">
            <img style="width:100%" src="../../assets/image/table/from_toeic_content.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="situation">
            <h3 class="title pr-3">考试主题</h3>
            <div class="line"></div>
          </div>
          <div class="row my-3">
            <img style="width:100%" src="../../assets/image/table/from_toeic_situation.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="guide">
            <h3 class="title pr-3">能力对标</h3>
            <div class="line"></div>
          </div>
          <div class="row my-3">
            <img style="width:100%" src="../../assets/image/table/from_toeic_fraction.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="practice">
            <h3 class="title pr-3">托业模考</h3>
            <div class="line"></div>
          </div>
          <div class="d-md-flex justify-content-between my-4">
            <div class="p-2  rwd-hide" v-for="(item, index) in exams" :key="index" @click="goPage(item.id,item.view_id)">
              <button type="button" style="width:100%" class="btn btn-dark p-4">第{{index+1}}回</button>
            </div>
            <div class="rwd-show">
              <h4>
                为提供最佳应考体验，本站多益模拟测验，不适用于手机介面，仅开放桌机或笔电进行考试。
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../store/api'
export default {
  name: 'Topic-raiders',
  components: {

  },
  data () {
    return {
      exams: null
    }
  },
  computed: {
  },
  mounted () {
    this.getExamData()
    this.scrollTop()
  },
  methods: {
    async getExamData () {
      const { data } = await api.get('/contents/exams?page=1&pageSize=10&type=simulation')
      this.exams = data.data
    },
    goPage (id, viewId) {
      this.$router.push({ name: 'Examination', params: { examId: id ,viewId: viewId } })
    },
    goAnchor(id, yOffset = 0) {
      const el = document.getElementById(id)
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({top: y, behavior: 'smooth'})
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/custom.scss";
  .banner {
    height: 60vh;
    background-image: url('../../assets/image/pic/banner_bg_toeic_top.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .title-box {
      width: 50%;
      .text-box {
        width: 50%;
        margin: auto;
        h4 {
          line-height: 200px;
          text-align: center;
          font-size: 150px;
          font-weight: 900;
          color: #fff;
          -webkit-text-stroke: 1.5px rgb(0, 0, 0);
        }
      }
    }
  }
  @media(max-width: 1080px){
    .banner {
      background-position: 0 0;
      .banner-box {
        display: none !important;
      }
    }
  }
  .container {
    text-align: left;
  }
  .anctors {
    button {
      min-width: 150px;
      font-size: 1.2rem;
    }
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .rwd-show {
    display: none;
  }
  @media screen and (max-width: 420px) {
    .rwd-hide {
      display: none !important;
    }
    .rwd-show {
      display: block;
    }
    .banner {
      height: 30vh;
      background-size: contain;
      background-image: url('../../assets/image/pic/banner_bg_toeic_top_SM_W.jpg');
    }
  }
</style>
