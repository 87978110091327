<template>
  <div class="login-bg">
    <div class="banner">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 align-self-center">
            <h3 class="sub-title">英语实战网 全面提升就业力</h3>
            <h1 class="title">快来测试你的职业英语能力吧！</h1>
            <a type="button" target="_blank" href="http://learningpro.mikecrm.com/sYC0TpY" class="btn btn-light mt-4">
              立即申请免费托业模考
            </a>
          </div>
          <div class="mt-4 col-md-6 align-self-center">
            <div class="login-box pop-box">
              <img style="width:100px" src="../assets/image/pic/logo_liveLearning.png" alt="">
              <span style="float:right;font-size:.5rem">粤ICP备2022150140号</span>
              <!-- <font-awesome-icon class="pointer" @click="close" size="2x" :icon="['fa', 'times']"  style="color:rgba(255, 0, 0, .6);float: right;"/> -->
              <div class="mt-5">
                <form class="mt-4 px-4" v-if="!forget">
                  <div class="group">
                    <input class="input-text" v-on:keyup.enter="login" v-model="auth.account" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label class="input-text-label">{{ $t('Login.please_enter_your_email') }}</label>
                  </div>
                  <div class="group">
                    <input class="input-text" v-on:keyup.enter="login" v-model="auth.password" type="password" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label class="input-text-label">{{ $t('Login.please_enter_password') }}</label>
                  </div>
                  <div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="checkbox1" v-model="remember">
                      <label class="form-check-label" for="checkbox1">
                        记住我
                      </label>
                    </div>
                    <button type="button" style="width:100%" class="btn btn-danger mt-2" @click="login">{{ $t('Login.login') }}</button>
                  </div>
                  <!-- <div class="mt-2" style="text-align: right;">
                    <span class="underLine pointer" @click="openForgetPage">
                      {{ $t('Login.forgot_Password') }}
                    </span>
                  </div> -->
                </form>
                <form class="mt-4 px-4" v-else>
                  <div class="group">
                    <input class="input-text" v-model="auth.account" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label class="input-text-label">{{ $t('Login.please_enter_your_email') }}</label>
                  </div>
                  <button type="button" style="width:100%" class="btn btn-danger" @click="getEmail">{{ $t('Login.send_verification_email') }}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/api'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Login',
  data () {
    return {
      forget: false,
      toggle: false,
      auth: {
        account: '',
        password: ''
      },
      remember: window.localStorage.getItem('account') ? true : false
    }
  },
  props: {
    assistData: {
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'authorized',
      'authInfo'
    ])
  },
  watch: {
  },
  mounted () {
    if (this.remember) {
      this.auth.account = window.localStorage.getItem('account')
    }
    if (this.authorized) {
      this.$router.push({ name: 'Raiders' })
    }
  },
  methods: {
    ...mapActions({
      dologin: 'login',
      getUser: 'getUser'
    }),
    openLogin () {
      this.toggle = true
      this.forget = false
    },
    close () {
      this.toggle = false
      this.forget = false
    },
    openForgetPage () {
      this.forget = true
      this.auth.account = ''
    },
    async login () {
      const vm = this
      try {
        await this.dologin({ email: this.auth.account, password: this.auth.password })
        await this.getUser()
        if (this.remember) {
          window.localStorage.setItem('account', vm.auth.account)
        } else {
          window.localStorage.removeItem('account')
        }
        this.$router.push({ name: 'Raiders' })
      } catch (error) {
        if (error) {
          this.setTimeout(
            this.$swal({
            title: '登录失败',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '确定'
          })
          , 1000)
        }
      }
    },
    async getEmail () {
      try {
        const {data} = await api.post(`password/email?email=${this.auth.account}`)
        this.$swal({
          title: data.email || data.status,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: '确定'
        })
      } catch (error) {
        this.$swal({
          title: error.response.data.errors.email[0],
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '确定'
        })
      }
    }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .login-bg {
    position: relative;
  }
  .group {
    position:relative;
    margin-bottom: 35px;
  }
  .input-text {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
  }
  .input-text:focus	{ outline: none; }

  /* LABEL ======================================= */
  .input-text-label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  .input-text:focus ~ label, input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #000000;
  }
  .arrow {
    width: 50px;
  }
  /* BOTTOM BARS ================================= */
  /* active state */
  input:focus ~ .bar:before, input:focus ~ .bar:after {
    width: 50%;
  }
.underLine {
  text-decoration: underline;
}
  .title {
    font-weight: bolder;
  }
  .banner {
    height: 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-image: url('../assets/image/home/banner01_bg_W.png');
    .container {
      height: 100vh;
      .row {
        text-align: left;
        height: 100%;
        h1,h3 {
          text-shadow: black 0.1em 0.1em 0.2em !important;
          color: #fff;
        }
        h1 {
          font-size: 4rem;
        }
      }
    }
  }
  .topic-banner {
    background-size: cover;
    background-image: url('../assets/image/home/banner03_bg.jpg');
  }
  .architecture-banner {
    background-size: cover;
    background-image: url('../assets/image/home/banner02_bg.jpg');
  }
  .feature-banner {
    // height: 50vh;
    background-size: contain;
    background-image: url('../assets/image/home/banner02_bg.jpg');
    .card {
      min-height: 350px;
      box-shadow: 3px 3px 5px rgb(141, 141, 141);
      .card-img-top {
        border: #fff 12px solid;
      }
      .card-header {
        background-color: #E82449;
        font-weight: bold;
        font-size: 1.5rem;
        color: #fff;
      }
    }
    .info-box {
      white-space:nowrap;
      font-weight: bolder;
      .info-title {
        color: red;
      }
      div	{
        padding: 10px;
        background-color: #fff;
        margin: 5px;
      }
    }
  }
  .process-banner {
    // height: 80vh;
    background-size: cover;
    background-image: url('../assets/image/home/banner05_bg.jpg');
  }
  .apply-box {
    background-color: #E82449;
    color: #fff;
    h3 {
      font-weight: bolder;
      font-size: 2rem;
    }
  }
  .rwd-banner {
    display: none;
  }
  .feed-back {
    overflow:hidden;
    background-color: $light-color;
  }
  .info-bg {
    padding: 20px;
    background-color: #FFB0A5;
  }
  .login-box {
    padding: 40px;
    border-radius: 20px;
    box-shadow: 10px;
    background-color: #fff;
    width: 580px;
    max-height: 580px;
    box-shadow: 10px 12px 12px 12px rgba(0, 0, 0, 0.4);
    z-index: 1000;
    margin: auto;
  }
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media screen and (max-width: 601px) {
  .login-box {
    width: 340px !important;
  }
  .rwd-banner {
    display: block !important;
  }
  .banner {
    background-attachment: scroll;
    background-image: url('../assets/image/home/rwd-bg.jpg');
    height: 1200px;
    .title {
      font-size: 2.5rem !important;
    }
    .sub-title {
      font-size: 1.25rem !important;
    }
  }
  .pop-up {
    width: 100%;
  }
}
</style>
