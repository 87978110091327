<template >
  <nav aria-label="Page navigation" v-if="total > 0">
    <ul class="pagination justify-content-center">
      <li class="page-item pointer" v-bind:class="{ disabled: current <= 1 }" v-show="!(current <= 1)">
        <a class="page-link border-primary" @click="go(current - 1)">&lt;</a>
      </li>
      <li class="page-item pointer" v-for="(page ,index) in range" :key="index" v-bind:class="{ active: page === current }">
        <a class="page-link border-primary text-primary" @click="go(page)">{{ (page >= 10) ? page : '0' + page }}</a>
      </li>
      <li class="page-item pointer" v-bind:class="{ disabled: current >= total }" v-show="!(current >= total)">
        <a class="page-link border-primary text-primary" @click="go(current + 1)">&gt;</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import range from 'lodash/range'

export default {
  name: 'Pagination',

  props: {
    current: {
      type: Number,
      required: true,
      default: 1
    },

    total: {
      type: Number,
      required: true,
      default: 1
    }
  },

  computed: {
    range () {
      var screenWidth = window.innerWidth
      console.log(screenWidth)
      const start = this.current - (screenWidth > 1200 ? 5 : 3) > 0 ? this.current - (screenWidth > 1200 ? 5 : 3) : 1
      const end = this.current + (screenWidth > 1200 ? 5 : 3) < this.total ? this.current + (screenWidth > 1200 ? 5 : 3) : this.total
      return range(start, end + 1)
    }
  },

  methods: {
    go (page) {
      const toPage = page >= 1 && page <= this.total ? page : this.current
      this.$emit('page', toPage)
      this.scrollTop()
    },

    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  // @import '../assets/style/color.scss';

  .border-primary {
    border-color: red !important;
  }

  .page-item {
    margin-right: 1em;

    &.active {
      .page-link {
        background-color: red !important;
        color: #fff!important;
      }
    }

    .page-link {
      color: red !important;
      font-weight: 800;
      border-width: 3px;
    }
  }
</style>
