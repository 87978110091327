<template>
  <div>
    <!-- <h5 style="font-size: 1.3rem;" v-if="inputType==='daily_english'">{{ $t('Home.FunEnglish') }}</h5>
    <h5 style="font-size: 1.3rem;" v-if="inputType==='hot_word'">最熱搜話題字，就讓老師告訴你！</h5>
    <h5 style="font-size: 1.3rem;" v-if="inputType==='travel_taiwan'">各地人文風情、自然地景、娛樂觀光盡收眼底！</h5> -->
    <div class="collection-tag" >
      <span class="pointer p-2" @click="addCollections(inputData)" v-if="!inputData.is_favorite">
        <img src="../assets/image/icon&btn/icon_have_btn_function/btn_function_mark_off.png" alt="">
        <span class="rwd-hide">
          {{ $t('Home.Keep') }}
        </span>
      </span>
      <span class="pointer p-2" @click=" removeCollections(inputData)" v-else>
        <img src="../assets/image/pic/btn_function_mark_on.png"  alt="">
        <span class="rwd-hide">
          {{ $t('Home.Keep') }}
        </span>
      </span>
      <span class="pointer p-2" @click="$router.push({ name: inputType})">
        <img src="../assets/image/icon&btn/icon_have_btn_function/btn_function_show_list.png" alt="">
        <span class="rwd-hide">
          {{ $t('Home.watch_more') }}
        </span>
      </span>
    </div>
    <h5 class="mt-3" style="font-size: 1.3rem;">{{inputData.name}}</h5>
    <div class="player-layout">
      <player style="width:100%;" class="m-auto my-3" :url="inputData.sections[0].data.path + '#t=0.001'"/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import api from '../store/api'
import Player from 'live-player/dist/Player'
export default {
  name: 'DailyModal',
  data () {
    return {
    }
  },
  props:{
    inputData: {
      require: false
    },
    inputType: {
      require: false
    }
  },
  mounted () {
  },
  methods: {
    async addCollections (el) {
      const json = {
        public_at: el.public_at,
        url: el.sections[0].data.path
      }
      try {
        const fileData = new window.FormData()
        fileData.append('json', JSON.stringify(json))
        await api.post(`collections/${this.inputType}/${this.inputData.id}`, fileData)
        switch (this.inputType) {
          case 'daily_english':
            this.$parent.getEnglish()
          break
          case 'hot_word':
            this.$parent.getHotWord()
          break
          case 'travel_taiwan':
            this.$parent.getTravelTaiwan()
          break
        }
      } catch (error) {
        console.error(error.response.data)
      }
    },
    async removeCollections () {
      try {
        await api.delete(`collections/${this.inputType}/${this.inputData.id}`)
        switch (this.inputType) {
          case 'daily_english':
            this.$parent.getEnglish()
          break
          case 'hot_word':
            this.$parent.getHotWord()
          break
          case 'travel_taiwan':
            this.$parent.getTravelTaiwan()
          break
        }
      } catch (error) {
        console.error(error.response.data)
      }
    }
  },
  components: {
    Player
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/custom.scss";
  .collection-tag {
    text-align: right;
  }
  .player-layout {
    margin-top: 30px;
    width: 100%;
  }
</style>
