<template>
  <div id="app">
    <loading :active.sync="isLoading"></loading>
    <Navbar v-if="authorized" />
    <router-view :idleTime="count" v-on:isIdle="checkIdle" :key="$route.fullPath" :class="{'main-info':authorized}"/>
    <!-- <Footer/> -->
    <span v-if="authorized && !isHide" style="float:right">粤ICP备2022150140号</span>
    <!-- <Announcement v-if="authorized && !isHide"/> -->
  </div>
</template>
<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import Announcement from '@/components/Announcement.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'App',
  data () {
    return {
      isloading: true,
      autoLogout: 30,
      count: 0
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'authorized'
    ]),
    isHide() {
      return this.$route.name === 'CourseLesson'
    }
  },
  components: {
    Navbar,
    Loading,
    Footer,
    Announcement
  },
  beforeDestroy () {
    this.clearTimer()
  },
  mounted () {
    document.onmousemove = (event) => {
      let x1 = event.clientX
      let y1 = event.clientY
      if (this.x !== x1 || this.y !== y1) {
        this.count = 0
      }
      this.x = x1
      this.y = y1
    }
    document.onkeydown = () => {
      this.count = 0
    }
    document.onscroll = () => {
      this.count = 0
    }
    this.setTimer()
  },
  methods: {
    ...mapActions({
      dologout: 'logout'
    }),
    clearTimer () {
      clearInterval(window.myTimer)
      window.myTimer = null
    },
    setTimer () {
      this.count = 0
      if (!window.myTimer) {
        window.myTimer = window.setInterval(this.cookieTimeout, 1000)
      }
    },
    cookieTimeout () {
      let outTime = this.autoLogout
      this.count++
      if (this.count === outTime * 60) {

      }
      if (this.count === outTime * 60) {
        setTimeout(this.logout, 10000)
      }
    },
    logout () {
      if (this.count >= this.autoLogout * 60 && this.authInfo.authorized === true) {
        this.$router.push({ name: 'Login' })
        this.$swal({
          title: '您已闲置30分钟，已被退出',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: '确定'
        })
        this.dologout()
      }
    },
    checkIdle (val) {
      if (val === 'playing') {
        this.clearTimer()
      } else {
        this.setTimer()
      }
    }
  }
}
</script>

<style lang="scss">
* {
  font-family: Avenir, Helvetica, Arial, sans-serif, 微軟正黑體 !important;
  box-sizing: border-box;
}
#app {
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.main-info {
  margin: 93px 0 0 0;
  min-height: calc(100vh - 93px);
}
.btn-general {
  border-radius: 20px !important;
  width: 150px;
}
input:focus {
  outline: none;
}

.success {
  font-size: 1.5rem;
  margin-top: 60px;
}
.error {
  text-align: left;
  color: red;
  font-size: .8rem;
}
::placeholder {
  color: rgb(165, 165, 165);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(165, 165, 165);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(165, 165, 165);
}
@media screen and (max-width: 450px) {
	.main-info {
    margin: 110px 0 0 0;
  }
}
</style>
