<template>
  <div v-if="inputNews" class="daily-video">
    <popUp v-if="toggle" v-on:doClose="close">
      <div slot="info" class="info-main" >
        <div v-if="newsData" class="video-box">
          <h2>{{inputNews.name}}</h2>
          <h5>{{inputNews.name_en}}</h5>
          <!-- <player style="width:0%;" class="m-auto my-3" :url="newsData.modules[0].video"/> -->
          <live-player ref="liveplayer" :moduleEnable="['video', 'article', 'ebook', 'text']" :liveData="newsData" v-if="newsData.modules.length > 0 " />
          <!-- <live-player2 ref="liimage.veplayer" :moduleEnable="['video', 'article', 'ebook', 'text']" :liveData="newsData" v-if="newsData.modules.length > 0 " /> -->
        </div>
      </div>
    </popUp>
    <!-- <h5 style="text-align:left">{{moment(inputNews.created_at).format('YYYY/MM/DD')}}</h5> -->
    <div class="title d-flex justify-content-between">
      <div style="text-align:left;width:68%" class="pt-2">
        <h2>{{inputNews.name}}</h2>
        <h5>{{inputNews.name_en}}</h5>
      </div>
      <div class="d-flex" style="text-align: right;">
        <span class="pointer p-2" v-if="!inputNews.is_favorite" @click="addCollections(newsData)" ><img src="../assets/image/icon&btn/icon_have_btn_function/btn_function_mark_off.png" alt="">
          <span class="rwd-hide">
            {{ $t('Home.Keep') }}
          </span>
        </span>
        <span class="pointer p-2" v-else @click="removeCollections()" ><img  src="../assets/image/pic/btn_function_mark_on.png"  alt="">
          <span class="rwd-hide">
            {{ $t('Home.Keep') }}
          </span>
        </span>
          <span class="pointer p-2" @click="$router.push({ name: 'News'})"><img src="../assets/image/icon&btn/icon_have_btn_function/btn_function_show_list.png" alt="">
            <span class="rwd-hide">{{ $t('Home.watch_more') }}</span>
          </span>
      </div>
    </div>
    <div class="d-md-flex align-items-stretch">
      <div class="daily-box rwd-box pr-3" style="width:100%">
        <img style="width:100%;" class="pointer" @click="toggle=true" v-if="inputNews" :src="inputNews.cover" alt="">
      </div>
      <div class="daily-box mt-md-0">
        <div v-if="newsData" class="text-box">
          <p v-for="(item, index) in newsData.modules[0].sentences" :key="index">
            {{item.text}}
          </p>
        </div>
        <div class="btn-box mt-3" style="text-align:right">
          <button type="button" @click="toggle=true" class="btn btn-danger">More</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../store/api'
import LivePlayer from 'live-player'
import Player from 'live-player/dist/Player'
import popUp from '@/components/PopUp.vue'
import LivePlayer2 from '@/views/LivePlayer.vue'
// import libraryApi from '../store/library/api'
export default {
  name: 'DailyNews',
  data () {
    return {
      toggle: false,
      newsData: null
    }
  },
  props:{
    inputNews: {
      require: false
    },
    view_id: {
      require: false
    }
  },
  components: {
    popUp,
    Player,
    LivePlayer,
    LivePlayer2
  },
  mounted () {
    this.getNewsData()
  },
  watch: {
  },
  methods: {
    close (close) {
      this.toggle = close
    },
    async getNewsData () {
      // const { data } = await libraryApi.get(`/v1/livelibrary/Lesson/${this.view_id}`)
      const { data } = await api.get(`/contents/lesson/${this.view_id}`)
      if (data.status === 'OK') {
        data.data.modules[0].vocabularies = []
        data.data.modules[0].highlights = []
        this.newsData = data.data
      }
    },
    async addCollections (el) {
      const json = {
        view_id: el.id,
        public_at: this.inputNews.public_at
      }
      try {
        const fileData = new window.FormData()
        fileData.append('json', JSON.stringify(json))
        await api.post(`collections/daily_news/${this.inputNews.id}`, fileData)
        this.$parent.getNews()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    async removeCollections () {
      try {
        await api.delete(`collections/daily_news/${this.inputNews.id}`)
        this.$parent.getNews()
      } catch (error) {
        console.error(error.response.data)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "../assets/styles/custom.scss";
  .daily-box {
    text-align: left;
    width: 100%;
    height: 350px;
    span {
      padding: 0px 10px;
    }
    .text-box {
      height: 85%;
      overflow: auto;
    }
  }
  .daily-video {
    h2 {
      font-weight: 800;
      font-size: 1.5rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
  .vocabulary {
    list-style: none;
  }
  @media screen and (max-width: 450px) {
  .daily-box {
    width: 90vw;
  }
  .rwd-box {
    height: 230px !important;
  }
	.info-main {
    margin: 110px 0;
  }
}
</style>
